import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../../config/firebase";
import { IAnimal } from "../models/animal";
import { Campaign } from "../models/campaign";
import { IDonation } from "../models/donation";
import { Gift, Reward } from "../models/reward";
import { Story } from "../models/story";

const REF = "animals";

export const all = async (...where: any): Promise<IAnimal[]> => {
  const ref = collection(db, REF);
  let animals;
  if (where) {
    animals = await getDocs(query(ref, ...where));
  } else {
    animals = await getDocs(ref);
  }

  if (!animals.empty) {
    return animals.docs.map(
      (a) =>
        ({
          ...a.data(),
          id: a.id,
        } as IAnimal)
    );
  }
  return [];
};

export const get = async (animalId: string): Promise<IAnimal> => {
  const animal = await getDoc(doc(db, REF, animalId));
  if (animal.exists()) {
    return {
      ...animal.data(),
      id: animal.id,
    } as IAnimal;
  }
  throw new Error("Animal not found");
};

export const add = async (animal: IAnimal): Promise<IAnimal> => {
  const newAnimal = {
    ...animal,
    shortDescription: {
      fr: animal.shortDescription.fr,
      en: animal.shortDescription.en,
    },
    fullDescription: {
      fr: animal.fullDescription.fr,
      en: animal.fullDescription.en,
    },
    type: {
      fr: animal.type.fr,
      en: animal.type.en,
    },
    birthDate: animal.birthDate !== "" ? animal.birthDate : undefined,
    birthPlace: animal.birthPlace !== "" ? animal.birthPlace : undefined,
    donations: animal.donations ?? null,
  } as any;

  delete newAnimal.id;
  delete newAnimal.campaign;
  delete newAnimal.donations;

  await setDoc(doc(db, REF, animal.id), newAnimal, { merge: true });

  return animal;
};

export const update = async (animalId: string, animal: Partial<IAnimal>): Promise<void> => {
  const updatedAnimal = { ...animal } as any;
  if (animal.shortDescription) {
    updatedAnimal.shortDescription = {
      fr: animal.shortDescription.fr,
      en: animal.shortDescription.en,
    };
  }
  if (animal.fullDescription) {
    updatedAnimal.fullDescription = {
      fr: animal.fullDescription.fr,
      en: animal.fullDescription.en,
    };
  }
  if (animal.type) {
    updatedAnimal.type = {
      fr: animal.type.fr,
      en: animal.type.en,
    };
  }

  if (animal.campaign) {
    updatedAnimal.campaign = {
      fr: animal.campaign.fr,
      en: animal.campaign.en,
    };
  }

  updatedAnimal.birthDate = animal.birthDate !== "" ? animal.birthDate : undefined;
  updatedAnimal.birthPlace = animal.birthPlace !== "" ? animal.birthPlace : undefined;

  delete updatedAnimal.id;
  delete updatedAnimal.donations;

  await setDoc(doc(db, REF, animalId), updatedAnimal, { merge: true });
};

export const updateGoal = async (animalId: string, amount: any): Promise<void> => {
  if (amount === "0" || amount === 0 || amount === undefined) {
    await updateDoc(doc(db, REF, animalId), {
      donations: null,
    });
  } else {
    await updateDoc(doc(db, REF, animalId), {
      "donations.goal": +amount,
    });
  }
};

export const remove = async (animalId: string): Promise<void> => {
  await deleteDoc(doc(db, REF, animalId));
};

export const allStories = async (animalId: string): Promise<Story[]> => {
  const ref = collection(db, REF, animalId, "stories");
  const snap = await getDocs(query(ref, orderBy("postedAt", "desc")));

  if (!snap.empty) {
    return snap.docs.map(
      (a) =>
        ({
          ...a.data(),
          id: a.id,
          content: a.data().content ?? [],
          createdAt: a.data().createdAt.toDate(),
          postedAt: a.data().postedAt.toDate(),
        } as Story)
    );
  }
  return [];
};

export const allDonations = async (animalId: string): Promise<IDonation[]> => {
  const ref = collection(db, REF, animalId, "donations");
  const snap = await getDocs(query(ref, orderBy("createdAt", "desc")));

  if (!snap.empty) {
    return snap.docs.map(
      (a: any) =>
        ({
          ...a.data(),
          id: a.id,
          createdAt: a.data().createdAt?.toDate ? a.data().createdAt?.toDate() : a.data().createdAt,
        } as IDonation)
    );
  }
  return [];
};

export const allCampaigns = async (animalId: string): Promise<Campaign[]> => {
  const ref = collection(db, REF, animalId, "campaigns");
  const snap = await getDocs(query(ref, where("endAt", ">", new Date()), orderBy("endAt", "asc")));

  if (!snap.empty) {
    return snap.docs.map(
      (a: any) =>
        ({
          ...a.data(),
          id: a.id,
          createdAt: a.data().createdAt.toDate(),
          startedAt: a.data().startedAt.toDate(),
          canceledAt: a.data().canceledAt?.toDate(),
          endAt: a.data().endAt.toDate(),
        } as Campaign)
    );
  }
  return [];
};

export const pastCampaigns = async (animalId: string): Promise<Campaign[]> => {
  const ref = collection(db, REF, animalId, "campaigns");
  const snap = await getDocs(query(ref, where("endAt", "<=", new Date()), orderBy("endAt", "desc")));

  if (!snap.empty) {
    return snap.docs.map(
      (a: any) =>
        ({
          ...a.data(),
          id: a.id,
          createdAt: a.data().createdAt.toDate(),
          startedAt: a.data().startedAt.toDate(),
          canceledAt: a.data().canceledAt?.toDate(),
          endAt: a.data().endAt.toDate(),
        } as Campaign)
    );
  }
  return [];
};

export const addCampaign = async (animalId: string, campaign: Campaign): Promise<Campaign> => {
  const ref = collection(db, REF, animalId, "campaigns");

  const newCampaign = {
    ...campaign,
    name: {
      fr: campaign.name.fr,
      en: campaign.name.en,
    },
    description: {
      fr: campaign.description.fr,
      en: campaign.description.en,
    },
    createdAt: new Date(),
    updatedAt: new Date(),
  } as any;

  delete newCampaign.id;

  const snap = await addDoc(ref, newCampaign);
  newCampaign.id = snap.id;

  return newCampaign;
};

export const editCampaign = async (animalId: string, campaignId: string, data: any): Promise<void> => {
  delete data.id;

  const ref = doc(db, REF, animalId, "campaigns", campaignId);
  await updateDoc(ref, data);
};

export const allRewards = async (animalId: string): Promise<Reward[]> => {
  const ref = collection(db, REF, animalId, "rewards");
  const snap = await getDocs(query(ref));

  if (!snap.empty) {
    return snap.docs.map(
      (a: any) =>
        ({
          ...a.data(),
          id: a.id,
        } as Reward)
    );
  }
  return [];
};

export const addReward = async (animalId: string, data: any): Promise<Reward> => {
  delete data.id;

  const now = new Date();
  const ref = collection(db, REF, animalId, "rewards");
  const doc = await addDoc(ref, {
    ...data,
    createdAt: now,
    updatedAt: now,
  });

  return {
    ...JSON.parse(JSON.stringify(data)),
    id: doc.id,
    createdAt: now,
    updatedAt: now,
  };
};

export const editReward = async (animalId: string, rewardId: string, data: any): Promise<void> => {
  delete data.id;
  const ref = doc(db, REF, animalId, "rewards", rewardId);
  await updateDoc(ref, JSON.parse(JSON.stringify(data)));
};

export const allGifts = async (animalId: string): Promise<Gift[]> => {
  const ref = collection(db, REF, animalId, "gifts");
  const snap = await getDocs(query(ref));

  if (!snap.empty) {
    return snap.docs.map(
      (a: any) =>
        ({
          ...a.data(),
          id: a.id,
        } as Gift)
    );
  }
  return [];
};

export const addGift = async (animalId: string, data: any): Promise<Gift> => {
  delete data.id;

  const now = new Date();
  const ref = collection(db, REF, animalId, "gifts");
  const doc = await addDoc(ref, {
    ...JSON.parse(JSON.stringify(data)),
    createdAt: now,
    updatedAt: now,
  });

  return {
    ...data,
    id: doc.id,
    createdAt: now,
    updatedAt: now,
  };
};

export const editGift = async (animalId: string, giftId: string, data: any): Promise<void> => {
  delete data.id;
  const ref = doc(db, REF, animalId, "gifts", giftId);
  await updateDoc(ref, JSON.parse(JSON.stringify(data)));
};
