import DeleteIcon from '@mui/icons-material/Delete';
import { LoadingButton } from '@mui/lab';
import { Avatar, Box, Button, Card, CardActions, CardContent, CardHeader, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, List, ListItem, ListItemText, Tab, Tabs, TextField, Typography } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import { differenceInYears, format } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../../auth/provideAuthHook';
import { usePark } from '../../../core/hooks/park/provideParkHook';
import { User } from '../../../core/models/user';
import * as UserService from '../../../core/services/user-service';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5, 
}));

export function UserShow() {
  const auth = useAuth();
  const parkHook = usePark();
  const { userId } = useParams();

  const [tab, setTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [emailConfirmDelete, setEmailConfirmDelete] = useState('');
  const [errorConfirm, setErrorConfirm] = useState<string | undefined>();

  const [user, setUser] = useState<User>();

  const navigate = useNavigate();

  const handleChangeTab = (event: any, newValue: number) => {
    setTab(newValue);
  };

  const handleDelete = (event: any) => {
    if (emailConfirmDelete === user?.email) {
      setErrorConfirm(undefined);
      setLoading(true);
      // deleteAnimal(animal.id).then(() => {
      //   setLoading(false);
      //   navigate(`/admin/parks/${parkId}`);
      // }).catch((err) => {
      //   setLoading(false);
      // });
    } else {
      setErrorConfirm('L\'email de l\'utilisateur n\'est pas identique.');
    }
  };

  const handleCloseDelete = () => {
    setErrorConfirm(undefined);
    setEmailConfirmDelete('');
    setOpenConfirmDelete(false);
  };

  const getAnimal = useCallback(() => {
    if (userId) {
      UserService.get(userId).then((u: User) => {
        setUser(u);
      }).catch(() => {
        navigate('/admin/users');
      });
    } else {
      navigate('/admin/users');
    }
    
  }, [navigate, userId]);

  useEffect(() => {
    getAnimal();
  }, [getAnimal]);

  if (!user) {
    return <CircularProgress />
  }

  return (
    <>
      {openConfirmDelete && <Dialog open={openConfirmDelete} onClose={handleCloseDelete}>
        <DialogTitle>Êtes-vous VRAIMENT certains de vouloir supprimer "{user.firstName} {user.lastName}" ({user.email}) ?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Cette action irrémédiable va <b>SUPPRIMER</b> l'utilisateur et tout son contenu.
          </DialogContentText>
          <TextField
            autoFocus
            value={emailConfirmDelete}
            margin="dense"
            label={`Confirmer l'email de l'utilisateur: ${user.email}`}
            fullWidth
            variant="standard"
            error={!!errorConfirm}
            helperText={errorConfirm}
            onChange={(event) => {
              setErrorConfirm('');
              setEmailConfirmDelete(event.target.value);
            }}
          />
          
        </DialogContent>
        <DialogActions>
          <LoadingButton loading={loading} onClick={handleCloseDelete}>Annuler</LoadingButton>
          <LoadingButton
            color="error"
            disabled={loading || emailConfirmDelete !== user.email}
            onClick={handleDelete}
            loading={loading}
            loadingPosition="start"
            startIcon={<DeleteIcon />}
            variant="contained"
          >
            Confirmer
          </LoadingButton>
        </DialogActions>
      </Dialog>}
      <Box component="main" sx={{ flex: 1, pt: 2, pb: 6, px: 4, bgcolor: '#eaeff1' }}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
          <Card>
            <CardHeader
              avatar={
                <Avatar sx={{ width: 45, height: 45, border: '3px solid green', bgcolor: user.image ? 'transparent' : 'green' }} src={user.image}>
                  {user.firstName[0]}{user.lastName[0]}
                </Avatar>
              }
              title={<Typography variant="h5">{user.firstName} {user.lastName}</Typography>}
            />
            <CardContent>
              <Typography variant="body1" component="div" align="center">
                Niveau {user.level}
              </Typography>
              <BorderLinearProgress color="success" variant="determinate" value={((user.xp ?? 0) / (100)) * 100} />
              <List>
                <ListItem alignItems="flex-start">
                  <ListItemText
                      primary="E-mail"
                      secondary={user.email}
                    />
                </ListItem>
                {user.email !== user.emailVerified && <ListItem alignItems="flex-start">
                  <ListItemText
                      primary="E-mail confirmé"
                      secondary={user.emailVerified}
                    />
                </ListItem>}
                <ListItem alignItems="flex-start">
                  <ListItemText
                      primary="Date de naissance"
                      secondary={`${user.birthDate} (${differenceInYears(new Date(), new Date(user.birthDate))} ans)`}
                    />
                </ListItem>
                <ListItem alignItems="flex-start">
                  <ListItemText
                      primary="Adresse"
                      secondary={`${user.address ?? ''}${user.addressApt ? ' ' + user.addressApt : ''}${user.address || user.addressApt ? ',' : ''} ${user.city ? user.city + ',' : ''} ${user.state ? user.state + ',' : ''} ${user.country}`}
                    />
                </ListItem>
                <ListItem alignItems="flex-start">
                  <ListItemText
                      primary="Langue préféré"
                      secondary={user.languagePreference}
                    />
                </ListItem>
                <ListItem alignItems="flex-start">
                  <ListItemText
                      primary="Profil privé"
                      secondary={user.private === true ? 'Oui' : user.private === false ? 'Non' : 'Non renseigné (Oui par défaut)'}
                    />
                </ListItem>
                <ListItem alignItems="flex-start">
                  <ListItemText
                      primary="Créer le"
                      secondary={user.createdAt ? format(user.createdAt.toDate(), 'dd/MM/yyyy HH:mm') : 'Non renseigné'}
                    />
                </ListItem>
                <ListItem alignItems="flex-start">
                  <ListItemText
                      primary="Dernière modification le"
                      secondary={user.updatedAt ? format(user.updatedAt.toDate(), 'dd/MM/yyyy HH:mm') : 'Non renseigné'}
                    />
                </ListItem>
              </List>
            </CardContent>
            <CardActions sx={{ justifyContent: "center"}}>
              <Button variant="text" color="error" onClick={() => {setOpenConfirmDelete(true)}}>Supprimer</Button>
              <Button variant="contained" onClick={() => {navigate(`/animals/${userId}/edit`)}}>Editer</Button>
            </CardActions>
          </Card>
          </Grid>
          <Grid item xs={9}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
              <Tabs value={tab} onChange={handleChangeTab}>
                <Tab label="Dashboard" />
                <Tab label="Dons" />
                <Tab label="Contreparties" />
                <Tab label="Follow" />
                <Tab label="Profil public" />
                <Tab label="Sécurité" />
                <Tab label="Configuration privé" />
              </Tabs>
            </Box>
            <Box>
              <div
                role="tabpanel"
                hidden={tab !== 0}
                id={`simple-tabpanel-${0}`}
                aria-labelledby={`simple-tab-${0}`}
              >
              </div>
              
              <div
                role="tabpanel"
                hidden={tab !== 1}
                id={`simple-tabpanel-${1}`}
                aria-labelledby={`simple-tab-${1}`}
              >
                <Card>
                  <CardContent>
                    
                  </CardContent>
                </Card>
              </div>
              <div
                role="tabpanel"
                hidden={tab !== 2}
                id={`simple-tabpanel-${2}`}
                aria-labelledby={`simple-tab-${2}`}
              >
               
              </div>
              <div
                role="tabpanel"
                hidden={tab !== 3}
                id={`simple-tabpanel-${3}`}
                aria-labelledby={`simple-tab-${3}`}
              >
                <Card>
                  <CardContent>
                    
                  </CardContent>
                </Card>
              </div>
              <div
                role="tabpanel"
                hidden={tab !== 4}
                id={`simple-tabpanel-${4}`}
                aria-labelledby={`simple-tab-${4}`}
              >
                <Card>
                  <CardContent>
                    <List>
                      <ListItem>
                        
                      </ListItem>
                    </List>
                    Followers list
                  </CardContent>
                </Card>
              </div>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
    
  );
}