import { Chip, LinearProgress, List, ListItem, ListItemIcon, ListItemText, styled } from "@mui/material";
import { format, formatDistanceToNowStrict } from 'date-fns';
import frLocale from 'date-fns/locale/fr';
import { useEffect, useState } from 'react';
import MenuButton from '../../../core/components/MenuButton';
import { IAnimal } from '../../../core/models/animal';
import { Campaign } from '../../../core/models/campaign';
import { pastCampaigns } from '../../../core/services/animal-service';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  width: 150,
  marginRight: 2
}));

const ListItemWithMultipleActions = styled(ListItem)(({ theme }) => ({
  '& .MuiListItemSecondaryAction-root': {
    display: 'flex',
    alignItems: 'center'
  }
}));

interface DonationCompaignPastProps {
  animal: IAnimal;
}

export default function DonationCompaignPast(props: DonationCompaignPastProps) {
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);

  useEffect(() => {
    if (props.animal.id) {
      pastCampaigns(props.animal.id).then((c) => {
        setCampaigns(c);
      });
    }
  }, [props.animal.id]);

  return (
    <List>
      {campaigns.map((campaign, index) => <ListItemWithMultipleActions
        key={index}
        secondaryAction={<>
          <BorderLinearProgress color="success" variant="determinate" value={(((campaign.donations?.donations ?? 0) / (campaign.donations?.goal ?? 0)) * 100) > 100 ? 100 : (((campaign.donations?.donations ?? 0) / (campaign.donations?.goal ?? 0)) * 100)} />
          <MenuButton items={[{ name: 'Voir', onClick: () => {} }, { name: 'Dupliquer', onClick: ()  => {}}]} />
        </>}
      >
        <ListItemIcon>
          {campaign.canceledAt ? <Chip size="small" label="ANNULER" color="error" /> : `${(((campaign.donations?.donations ?? 0) / (campaign.donations?.goal ?? 0)) * 100).toFixed()}%`}
        </ListItemIcon>
        <ListItemText primary={`${campaign.name.fr} • ${campaign.donations?.donations ?? 0} / ${campaign.donations?.goal ?? 0}€`} secondary={`Fini depuis ${formatDistanceToNowStrict(campaign.endAt, { locale: frLocale })} • ${format(campaign.startedAt, 'dd/MM/yyyy HH:mm')} au ${format(campaign.endAt, 'dd/MM/yyyy HH:mm')}`} />
      </ListItemWithMultipleActions>
      )}
    </List>
  );
}