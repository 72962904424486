export interface ILanguages {
  fr: string;
  en: string;
}

export class Languages implements ILanguages {
  fr: string;
  en: string;

  constructor(fr = '', en = '') {
    this.fr = fr;
    this.en = en;
  }
}