import CloseIcon from '@mui/icons-material/Close';
import { AppBar, Box, Card, CardContent, CardMedia, CardProps, Divider, Drawer, Toolbar } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { ReactNode, useEffect, useState } from 'react';
import FileUploaderMultiple, { IFile } from './FileUploader';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: 0,
    position: 'relative'
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const drawerWidth = 240;

const CardStory = styled(Card)<CardProps>(({ theme }) => ({
  maxWidth: 245,
  position: 'relative',
  '& .MuiCardHeader-root': {
    zIndex: 999
  },
  '& .MuiCardHeader-title': {
    color: '#fff',
    fontWeight: 'bold'
  },
  '& .MuiCardHeader-subheader': {
    color: '#aaa'
  },
  '& .MuiCardHeader-action button': {
    color: '#fff'
  }
}));

export interface DialogTitleProps {
  id: string;
  children?: ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

interface NewsStoryProps {
  open?: boolean;
}

export default function NewStory(props: NewsStoryProps) {
  const [open, setOpen] = useState(true);
  const [texts, setTexts] = useState<{
    value: string;
    color: string;
    rotate: string;
    size: number;
    top: number;
    left: number;
  }[]>([]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeImage = (files: IFile[]) => {

  }

  useEffect(() => {
    setOpen(props.open ?? false);
  }, [props.open]);

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
       Créer une story
      </Button>
      <BootstrapDialog
        fullWidth
        maxWidth="lg"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Nouvelle story
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Box sx={{ display: 'flex', position: 'relative' }}>
            <AppBar
              position="fixed"
              sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}
            >
              <Toolbar>
                <Typography variant="h6" noWrap component="div">
                  Apercu
                </Typography>
              </Toolbar>
            </AppBar>
            <Drawer
              sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                  width: drawerWidth,
                  boxSizing: 'border-box',
                },
              }}
              variant="permanent"
              anchor="left"
            >
              <Box sx={{ p: 2 }}>
                <Typography variant="h6" gutterBottom>Contenu multimédia</Typography>
                <FileUploaderMultiple
                  files={[]}
                  onChange={handleChangeImage}
                />
              </Box>
              <Divider />
              <Box sx={{ p: 2 }}>
                <Typography variant="h6" gutterBottom>Outils de création</Typography>
                
              </Box>
            </Drawer>
            <Box
              component="main"
              sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
            >
              <CardStory>
                <CardMedia
                  component="img"
                  height="500"
                  image="https://firebasestorage.googleapis.com/v0/b/graou-26802.appspot.com/o/animals%2FJpsMWbrDCEFgMxF44CLl%2Fstories%2FtQ58KRjSCB8NxF7ixWn4.jpeg?alt=media&token=934364e1-ffb0-4cef-99ab-57df11adde15"
                  alt="green iguana"
                />
                <CardContent sx={{ position: "absolute", top: 0 }}>
                  <Typography gutterBottom variant="h5" component="div">
                    Lizard
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Lizards are a widespread group of squamate reptiles, with over 6,000
                    species, ranging across all continents except Antarctica
                  </Typography>
                </CardContent>
              </CardStory>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Partager la story
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );

}
