import { Category } from "./category";
import { Donation } from "./donation";
import { Image } from "./image";
import { Languages } from "./languages";

export interface IPark {
  id: string;
  disable?: boolean;
  name: string;
  email?: string;
  website?: string;
  thumbnail: string;
  images: Image[];
  category: {
    icon: string;
    id: string;
    slug: string;
  };
  city: string;
  country: string;
  localization: {
    geohash: string;
    geopoint: {
      latitude: string;
      longitude: string;
    };
  };
  shortDescription: Languages;
  fullDescription: Languages;
  donations?: Donation | null;
  errors?: string[];
  createdAt?: Date;
  updatedAt?: Date;
}

export class Park implements IPark {
  id: string;
  disable?: boolean;
  name: string;
  email?: string;
  website?: string;
  localization: {
    geohash: string;
    geopoint: {
      latitude: string;
      longitude: string;
    };
  };
  thumbnail: string;
  images: Image[];
  category: { icon: string; id: string; slug: string };
  city: string;
  country: string;
  shortDescription: Languages;
  fullDescription: Languages;
  donations?: Donation | null;

  constructor(park?: IPark) {
    this.id = park?.id ?? "";
    this.disable = park?.disable ?? false;
    this.name = park?.name ?? "";
    this.email = park?.email ?? "";
    this.website = park?.website ?? "";
    this.thumbnail = park?.thumbnail ?? "";
    this.images = park?.images ?? [];
    this.category = park?.category ?? new Category();
    this.city = park?.city ?? "";
    this.country = park?.country ?? "FR";
    this.shortDescription = park?.shortDescription ?? new Languages();
    this.fullDescription = park?.fullDescription ?? new Languages();
    this.localization = park?.localization ?? { geohash: "", geopoint: { latitude: "", longitude: "" } };
    this.donations = park?.donations ?? { donations: 0, currency: "eur", goal: null };
  }

  static errors(park: IPark) {
    const errors = [];
    if (park.name === "") {
      errors.push({
        name: "name",
        message: "Le nom est obligatoire",
      });
    }
    if (park.category.id === "") {
      errors.push({
        name: "category",
        message: "La catégorie est obligatoire",
      });
    }
    if (park.city === "") {
      errors.push({
        name: "city",
        message: "La ville est obligatoire",
      });
    }
    if (park.country === "") {
      errors.push({
        name: "country",
        message: "Le pays est obligatoire",
      });
    }
    if (park.shortDescription.fr === "") {
      errors.push({
        name: "shortDescription.fr",
        message: "La courte description est obligatoire en français",
      });
    }
    if (park.fullDescription.fr === "") {
      errors.push({
        name: "fullDescription.fr",
        message: "La longue description est obligatoire en français",
      });
    }
    if (park.shortDescription.en === "") {
      errors.push({
        name: "shortDescription.en",
        message: "La courte description est obligatoire en anglais",
      });
    }
    if (park.fullDescription.en === "") {
      errors.push({
        name: "fullDescription.en",
        message: "La longue description est obligatoire en anglais",
      });
    }
    if (
      park.localization.geopoint.latitude !== "" &&
      (isNaN(+park.localization.geopoint.latitude) ||
        +park.localization.geopoint.latitude < -90 ||
        +park.localization.geopoint.latitude > 90)
    ) {
      errors.push({
        name: "localization.latitude",
        message: "La latitude est incorrecte",
      });
    }
    if (
      park.localization.geopoint.longitude !== "" &&
      (isNaN(+park.localization.geopoint.longitude) ||
        +park.localization.geopoint.longitude < -180 ||
        +park.localization.geopoint.longitude > 180)
    ) {
      errors.push({
        name: "localization.longitude",
        message: "La longitude est incorrecte",
      });
    }
    return errors;
  }
}
