import SearchIcon from '@mui/icons-material/Search';
import { Avatar, InputBase, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { visuallyHidden } from '@mui/utils';
import { format } from 'date-fns';
import { collection, getDocs } from 'firebase/firestore';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../../../config/firebase';

interface Data {
  id: string;
  email: string;
  emailVerified: string;
  image: string;
  firstName: string;
  lastName: string;
  country: string;
  level: number;
  privateUser?: boolean;
  languagePreference: string;
  createdAt: Date;
  updatedAt: Date;
}

function createData(
  id: string,
  email: string,
  emailVerified: string,
  image: string,
  firstName: string,
  lastName: string,
  country: string,
  level: number,
  privateUser: boolean | undefined,
  languagePreference: string,
  createdAt: Date,
  updatedAt: Date
): Data {
  return {
    id,
    email,
    emailVerified,
    image,
    firstName,
    lastName,
    country,
    level,
    privateUser,
    languagePreference,
    createdAt,
    updatedAt,
  };
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'email',
    numeric: false,
    label: 'Email',
  },
  {
    id: 'firstName',
    numeric: false,
    label: 'Prénom',
  },
  {
    id: 'lastName',
    numeric: false,
    label: 'Nom',
  },
  {
    id: 'country',
    numeric: false,
    label: 'Pays',
  },
  {
    id: 'level',
    numeric: true,
    label: 'Niveau',
  },
  {
    id: 'languagePreference',
    numeric: false,
    label: 'Language',
  },
  {
    id: 'privateUser',
    numeric: false,
    label: 'Profil privé',
  },
  {
    id: 'createdAt',
    numeric: false,
    label: 'Date de création',
  },
  {
    id: 'updatedAt',
    numeric: false,
    label: 'Dernière modification',
  },
];

interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, rowCount, onRequestSort } =
    props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            padding={'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const EnhancedTableToolbar = () => {
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 }
      }}
    >
    <Typography
      sx={{ flex: '1 1 100%' }}
      variant="h6"
      id="tableTitle"
      component="div"
    >
      Tous les utilisateurs de la plateforme
    </Typography>
    <Paper
      component="form"
      sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Rechercher par email"
        inputProps={{ 'aria-label': 'Rechercher par email' }}
      />
      <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
        <SearchIcon />
      </IconButton>
    </Paper>
    </Toolbar>
  );
};

export default function UserList() {
  const navigate = useNavigate();
  
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('email');
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(30);
  const [users, setUsers] = React.useState<any>([]);

  React.useEffect(() => {
    getDocs(collection(db, 'users')).then((docsSnap) => {
      if (!docsSnap.empty) {
        setUsers(docsSnap.docs.map((d: any) => {
          const data = d.data();
          return createData(
            d.id,
            data.email,
            data.emailVerified,
            data.image,
            data.firstName,
            data.lastName,
            data.country,
            data.level ?? 1,
            data.private,
            data.languagePreference ?? '',
            data.createdAt?.toDate(),
            data.updatedAt?.toDate(),
          );
        }));
      }
    });
  }, []);



  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    navigate(`/admin/users/${name}`);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;

  return (
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <EnhancedTableToolbar/>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={users.length}
              />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                rows.slice().sort(getComparator(order, orderBy)) */}
                {stableSort(users, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row: any, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.id)}
                        tabIndex={-1}
                        key={row.id}
                      >
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                        >
                          <ListItem>
                            <ListItemAvatar>
                              <Avatar sx={{ width: 35, height: 35, border: '3px solid green', bgcolor: row.image ? 'transparent' : 'green' }} src={row.image}>
                                {row.firstName[0]}{row.lastName[0]}
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={row.email} />
                          </ListItem>
                          
                        </TableCell>
                        <TableCell>{row.firstName}</TableCell>
                        <TableCell>{row.lastName}</TableCell>
                        <TableCell sx={{ width: '50px'}}>{row.country}</TableCell>
                        <TableCell sx={{ width: '50px'}}>{row.level}</TableCell>
                        <TableCell sx={{ width: '150px'}}>{row.languagePreference}</TableCell>
                        <TableCell sx={{ width: '150px'}}>{row.privateUser === true ? 'Oui' : row.privateUser === false ? 'Non' : ''}</TableCell>
                        <TableCell sx={{ width: '150px'}}>{row.createdAt ? format(row.createdAt, 'dd/MM/yyyy HH:mm') : ''}</TableCell>
                        <TableCell sx={{ width: '150px'}}>{row.updatedAt ? format(row.updatedAt, 'dd/MM/yyyy HH:mm') : ''}</TableCell>

                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 15, 30]}
            component="div"
            count={users.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
  );
}
