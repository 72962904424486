import { useProvideAuth, authContext } from "./provideAuthHook";

interface PropsProviderAuth {
  children: any
}

export const ProvideAuth = (props: PropsProviderAuth) => {
  const auth = useProvideAuth();
  return (
    <authContext.Provider value={auth}>
      {props.children}
    </authContext.Provider>
  );
};

