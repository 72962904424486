import styled from '@emotion/styled';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Avatar, Box, Card, CardHeader, CardMedia, CardProps, Chip, Grid, IconButton, Typography } from '@mui/material';
import { differenceInMinutes, format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../auth/provideAuthHook';
import NewStory from '../../core/components/NewStory';
import { IAnimal } from '../../core/models/animal';
import { Story, StoryType } from '../../core/models/story';
import * as AnimalService from '../../core/services/animal-service';

const CardStory = styled(Card)<CardProps>(({ theme }) => ({
  maxWidth: 345,
  position: 'relative',
  '& .MuiCardHeader-root': {
    zIndex: 999
  },
  '& .MuiCardHeader-title': {
    color: '#fff',
    fontWeight: 'bold'
  },
  '& .MuiCardHeader-subheader': {
    color: '#aaa'
  },
  '& .MuiCardHeader-action button': {
    color: '#fff'
  }
}));

interface StoryListProps {
  animal: IAnimal;
}

export default function StoryList(props: StoryListProps) {
  const authHook = useAuth();

  const [stories, setStories] = useState<Story[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    AnimalService.allStories(props.animal.id).then((ds: Story[]) => setStories(ds));
  }, [props.animal]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <NewStory />
      </Grid>
      {stories.map((story) => {
        const difference = differenceInMinutes(story.postedAt, new Date());
        const isActive = difference > -1440;
        const isFuture = difference > 0;
        return (
          <Grid item lg={3} md={6}>
            <CardStory>
              <CardHeader
                avatar={
                  <Avatar aria-label="recipe" src={props.animal.thumbnail} />
                }
                action={
                  <IconButton aria-label="settings">
                    <MoreVertIcon />
                  </IconButton>
                }
                sx={{ position: 'absolute', bgcolor: '#00000054', width: '100%' }}
                title={isFuture ? <Chip label="Pas encore active" color="warning" size="small" /> : isActive ? <><Chip label="Active" color="success" size="small" /> 150 vues</> : '150 vues'}
                subheader={format(story.postedAt, 'dd/MM/yyyy HH:mm')}
              />
              {story.type === StoryType.image && <CardMedia
                component="img"
                height="500"
                image={story.url}
                alt=""
              />}
              {story.type === StoryType.video && <CardMedia
                component="video"
                height="500"
                src={story.url}
                sx={{ bgcolor: '#252525'}}
                controls={true}
              />}
              {story.content.map((c) => {
                return (
                  <Box sx={{ position: 'absolute', left: `${c.position.left}%`, top: `${c.position.top}%`, transform: c.textStyle.transform }}>
                    <Typography sx={{ color: c.textStyle.color, fontSize: `${c.textStyle.size}px`, fontWeight: c.textStyle.weight}}>{c.text}</Typography>
                  </Box>
                );
              })}
            </CardStory>
          </Grid>
        );
      })}
    </Grid>
  );
}
