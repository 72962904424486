import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import InputLanguages from "../../../core/components/InputLanguages";
import { Animal } from "../../../core/models/animal";
import { ILanguages } from "../../../core/models/languages";
import { Gift, Recurrence, Reward, RewardPrice } from "../../../core/models/reward";
import { addGift } from "../../../core/services/animal-service";
import { GiftEdit } from "./GiftEdit";
import { RewardPriceComponent } from "./RewardPriceComponent";

interface RewardEditProps {
  errors: { name: string; message: string }[];
  open: boolean;
  gifts: Gift[];
  reward?: Reward;
  animal: Animal;
  onClose: (reward?: Reward) => void;
}

export function RewardEdit(props: RewardEditProps) {
  const [submitted, setSubmitted] = useState(false);
  const [openAddGift, setOpenAddGift] = useState(false);
  const [reward, setReward] = useState<Reward>(new Reward());
  const [gifts, setGifts] = useState<Gift[]>([]);

  const handleCloseAddGift = (gift?: Gift) => {
    if (gift) {
      if (Gift.errors(gift).length === 0) {
        addGift(props.animal.id, gift).then((gift: Gift) => {
          setOpenAddGift(false);
          gifts.push(gift);
          setGifts(gifts);
          const giftsUpdated = reward.gifts;
          giftsUpdated.push(gift);
          setReward({
            ...reward,
            gifts: giftsUpdated,
          });
        });
      }
    } else {
      setOpenAddGift(false);
    }
  };

  const handleChangeName = (value: ILanguages) => {
    setReward({
      ...reward,
      name: value,
    });
  };

  const handleChangeDescription = (value: ILanguages) => {
    setReward({
      ...reward,
      description: value,
    });
  };

  const handleChangePrice = (price: RewardPrice, index: number) => {
    const prices = reward.prices;
    prices[index] = price;
    setReward({
      ...reward,
      prices,
    });
  };

  const handleAddPrice = () => {
    const prices = reward.prices;
    prices.push({
      currency: "eur",
      min: undefined,
      max: undefined,
      recurrence: Recurrence.none,
    });
    setReward({
      ...reward,
      prices,
    });
  };

  const handleRemovePrice = (index: number) => {
    const prices = reward.prices;
    prices.splice(index, 1);
    setReward({
      ...reward,
      prices,
    });
  };

  const handleChangeGifts = (gifts: Gift[]) => {
    // const findNews = gifts.includes("new");
    setReward({
      ...reward,
      gifts: gifts.map((gift) => ({
        id: gift.id,
        name: gift.name,
        description: gift.description,
      })),
    });
  };

  const handleSubmit = () => {
    setSubmitted(true);
    props.onClose(reward);
  };

  useEffect(() => {
    if (props.reward) {
      setReward(props.reward);
    }
  }, [props.reward]);

  useEffect(() => {
    if (props.gifts) {
      setGifts(props.gifts);
    }
  }, [props.gifts]);

  return (
    <Fragment>
      {openAddGift && <GiftEdit errors={[]} open={openAddGift} onClose={handleCloseAddGift} />}
      <Dialog
        fullWidth
        maxWidth="md"
        open={props.open}
        onClose={() => props.onClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{props.reward ? `Modifier ${reward.name.fr}` : "Nouveau pack"}</DialogTitle>
        <DialogContent>
          <Alert severity="warning">
            <AlertTitle>Attention</AlertTitle>
            Avez-vous ajouté les <strong>contreparties</strong> que vous souhaitez avant de faire le pack ?
          </Alert>
          <InputLanguages
            label="Nom"
            key="name"
            required
            value={reward.name}
            onChange={handleChangeName}
            error={props.errors.find((e) => e.name.includes("name"))}
          />

          <InputLanguages
            key="description"
            label="Description"
            required
            value={reward.description}
            onChange={handleChangeDescription}
            error={props.errors.find((e) => e.name.includes("name"))}
          />

          <FormControl error={!!props.errors.find((e) => e.name === "gifts")} sx={{ mt: 2 }} fullWidth>
            <InputLabel id="select-gifts">Contreparties</InputLabel>
            <Select
              labelId="select-gifts"
              value={reward.gifts.map((g) => g.id) || []}
              label="Contreparties"
              error={!!props.errors.find((e) => e.name === "gifts")}
              multiple
              required
              onChange={(event) => {
                if (event.target.value.includes("new")) {
                  setOpenAddGift(true);
                } else {
                  handleChangeGifts(props.gifts.filter((g: Gift) => g.id && event.target.value.includes(g.id)));
                }
              }}
            >
              {props.gifts.map((c: any) => (
                <MenuItem key={c.id} value={c.id}>
                  {c.name.fr}
                </MenuItem>
              ))}
              <MenuItem key="new" value="new">
                Créer une nouvelle contrepartie
              </MenuItem>
            </Select>
            <FormHelperText>{props.errors.find((e) => e.name === "gifts")?.message}</FormHelperText>
          </FormControl>

          <Box sx={{ width: "100%", mt: 2 }}>
            <FormControl error={!!props.errors.find((e) => e.name === "prices")} sx={{ mt: 2 }} fullWidth>
              <InputLabel sx={{ position: "relative" }}>Prix</InputLabel>
              <Card sx={{ p: 2, mt: 2 }}>
                {reward.prices.map((price, index) => (
                  <Box key={index} sx={{ mb: 4 }}>
                    <RewardPriceComponent
                      {...price}
                      onSubmit={(price) => handleChangePrice(price, index)}
                      onDelete={() => handleRemovePrice(index)}
                    />
                  </Box>
                ))}
                <Button onClick={handleAddPrice} variant="contained" sx={{ mt: 2 }}>
                  Ajouter un prix
                </Button>
              </Card>
              <FormHelperText>{props.errors.find((e) => e.name === "prices")?.message}</FormHelperText>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => props.onClose()} variant="text">
            Annuler
          </Button>
          <Button onClick={handleSubmit} variant="contained">
            {reward.id ? "Modifier" : "Créer"}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
