import { EmailAuthProvider, FacebookAuthProvider, GoogleAuthProvider, onAuthStateChanged, signInWithPopup, signOut, User as UserFirebase } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { createContext, useContext, useEffect, useState } from "react";
import { auth, db } from "../config/firebase";
import { User } from "../core/models/user";

interface CustomClaims {
  roles: string[];
  admin: boolean;
  parks: {
    id: string;
    roles: string[];
  }[];
}

export const authContext = createContext<{
  user: User | null;
  userProvider: UserFirebase | null;
  claims: CustomClaims | null;
  loading: boolean;
  superAdmin: boolean;
  signin: (provider: string) => void;
  signout: () => void;
}>({
  user: null,
  userProvider: null,
  claims: null,
  superAdmin: false,
  loading: false,
  signin: (provider) => null,
  signout: () => null,
});

export const useAuth = () => {
  return useContext(authContext);
}

export const useProvideAuth = () => {
  const [user, setUser] = useState<User | null>(null);
  const [userProvider, setUserProvider] = useState<UserFirebase | null>(null);
  const [claims, setClaims] = useState<CustomClaims | null>(null);
  
  const [superAdmin, setSuperAdmin] = useState(false);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    onAuthStateChanged(auth, async (userFirebase) => {
      setLoading(true);
      setSuperAdmin(false);
      setUser(null);
      setUserProvider(userFirebase);
      if (userFirebase) {
        const idToken: any = await userFirebase.getIdTokenResult();
        const claims: CustomClaims = idToken.claims;
        setClaims(claims);
        setSuperAdmin(claims.admin);
        const parksClaim = claims.parks;
        if (parksClaim?.length > 0 || claims.roles?.length > 0 || claims.admin) {
          const userRef = doc(db, 'users/' + userFirebase.uid);
          const userSnap = await getDoc(userRef);
          if (userSnap.exists()) {
            setUser({
              ...userSnap.data() as User,
              id: userSnap.id,
            });
          }
        }
      }
      setLoading(false);
    });
  }, []);

  const signin = async (providerString: string) => {
    setLoading(true);
    let provider;
    switch (providerString) {
      case GoogleAuthProvider.PROVIDER_ID:
        provider = new GoogleAuthProvider();
        break;
      case FacebookAuthProvider.PROVIDER_ID:
        provider = new FacebookAuthProvider();
        break;
      case EmailAuthProvider.PROVIDER_ID:
        provider = new EmailAuthProvider();
        break;
      default:
        break;
    }

    if (!provider) {
      setLoading(false);
      setError('Provider not found');
      return;
    }

    signInWithPopup(auth, provider)
      .catch((error) => {
        setError(error.message);
        return;
      });
  };

  const signout = async () => {
    await signOut(auth);
  };

  return {
    user,
    userProvider,
    claims,
    superAdmin,
    loading,
    signin,
    signout,
  };
}
