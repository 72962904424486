import DateRangeIcon from '@mui/icons-material/DateRange';
import HomeIcon from '@mui/icons-material/Home';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer, { DrawerProps } from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useAuth } from '../../auth/provideAuthHook';
import { usePark } from '../../core/hooks/park/provideParkHook';
import NavigatorItem from './NavigatorItem';

const categories = [
  {
    id: 'Menu',
    children: [
      { id: 'Dashboard', icon: <HomeIcon />, to: '/' },
      { id: 'Parc', icon: <DateRangeIcon />, to: '/park' },
      { id: 'Animaux', icon: <DateRangeIcon />, to: '/animals' },
      { id: 'Équipe', icon: <DateRangeIcon />, to: '/users' },
    ],
  },
];

const superAdmin = [
  {
    id: 'Super Admin',
    children: [
      { id: 'Dashboard', icon: <HomeIcon />, to: '/admin/' },
      { id: 'Catégories', icon: <HomeIcon />, to: '/admin/categories' },
      { id: 'Utilisateurs', icon: <DateRangeIcon />, to: '/admin/users' },
    ],
  },
];

const item = {
  py: '2px',
  px: 3,
  color: 'rgba(255, 255, 255, 0.7)',
  '&:hover, &:focus': {
    bgcolor: 'rgba(255, 255, 255, 0.08)',
  },
};

const itemCategory = {
  boxShadow: '0 -1px 0 rgb(255,255,255,0.1) inset',
  py: 1.5,
  px: 3,
};

export default function Navigator(props: DrawerProps) {
  const { ...other } = props;

  const authHook = useAuth();
  const parkHook = usePark();

  return (
    <Drawer variant="permanent" {...other}>
      <List disablePadding>
        <ListItem sx={{ ...item, ...itemCategory, fontSize: 22, color: '#fff' }}>
          Graoo
        </ListItem>
        {authHook.superAdmin && superAdmin.map(({ id, children }) => (
          <Box key={id} sx={{ bgcolor: '#101F33' }}>
            <ListItem sx={{ py: 2, px: 3 }}>
              <ListItemText sx={{ color: '#fff' }}>{id}</ListItemText>
            </ListItem>
            {children.map((child: any) => (
              <NavigatorItem {...child} key={child.id} />
            ))}
            <Divider sx={{ mt: 2 }} />
          </Box>
        ))}
        {parkHook.park && categories.map(({ id, children }) => (
          <Box key={id} sx={{ bgcolor: '#101F33' }}>
            <ListItem sx={{ py: 2, px: 3 }}>
              <ListItemText sx={{ color: '#fff' }}>{id}</ListItemText>
            </ListItem>
            {children.map((child: any) => (
              <NavigatorItem {...child} key={child.id} />
            ))}
            <Divider sx={{ mt: 2 }} />
          </Box>
        ))}
      </List>
    </Drawer>
  );
}