import { collection, deleteDoc, doc, GeoPoint, getDoc, getDocs, query, setDoc } from "firebase/firestore";
import { db } from "../../config/firebase";
import { IPark } from "../models/park";
import Geohash from "../utils/geohash";

const REF = "parks";

export const all = async (...where: any): Promise<IPark[]> => {
  const ref = collection(db, REF);
  let parks;
  if (where) {
    parks = await getDocs(query(ref, ...where));
  } else {
    parks = await getDocs(ref);
  }

  if (!parks.empty) {
    return parks.docs.map(
      (p) =>
        ({
          ...p.data(),
          id: p.id,
        } as IPark)
    );
  }
  return [];
};

export const get = async (parkId: string): Promise<IPark> => {
  const park = await getDoc(doc(db, REF, parkId));
  if (park.exists()) {
    return {
      ...park.data(),
      id: park.id,
    } as IPark;
  }
  throw new Error("Park not found");
};

export const add = async (park: IPark): Promise<IPark> => {
  const newPark = {
    ...park,
    localization:
      park.localization.geopoint.latitude !== "" && park.localization.geopoint.longitude !== ""
        ? {
            geohash: Geohash.encode(+park.localization.geopoint.latitude, +park.localization.geopoint.longitude),
            geopoint: new GeoPoint(+park.localization.geopoint.latitude, +park.localization.geopoint.longitude),
          }
        : { geohash: "", geopoint: null },
    shortDescription: {
      fr: park.shortDescription.fr,
      en: park.shortDescription.en,
    },
    fullDescription: {
      fr: park.fullDescription.fr,
      en: park.fullDescription.en,
    },
  } as any;

  delete newPark.id;

  await setDoc(doc(db, REF, park.id), newPark, { merge: true });

  return park;
};

export const update = async (parkId: string, park: Partial<IPark>): Promise<void> => {
  const updatedPark = { ...park } as any;
  if (park.localization) {
    updatedPark.localization =
      park.localization.geopoint.latitude !== "" && park.localization.geopoint.longitude !== ""
        ? {
            geohash: Geohash.encode(+park.localization.geopoint.latitude, +park.localization.geopoint.longitude),
            geopoint: new GeoPoint(+park.localization.geopoint.latitude, +park.localization.geopoint.longitude),
          }
        : { geohash: "", geopoint: null };
  }
  if (park.shortDescription) {
    updatedPark.shortDescription = {
      fr: park.shortDescription.fr,
      en: park.shortDescription.en,
    };
  }
  if (park.fullDescription) {
    updatedPark.fullDescription = {
      fr: park.fullDescription.fr,
      en: park.fullDescription.en,
    };
  }

  delete updatedPark.id;

  await setDoc(doc(db, REF, parkId), updatedPark, { merge: true });
};

export const remove = async (parkId: string): Promise<void> => {
  await deleteDoc(doc(db, REF, parkId));
};
