import AddIcon from "@mui/icons-material/Add";
import {
  Avatar,
  Button,
  Chip,
  LinearProgress,
  ListItem,
  ListItemAvatar,
  ListItemText,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from "@mui/material";
import Box from "@mui/material/Box";
import { collection, getDocs, query, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../../config/firebase";
import { usePark } from "../../core/hooks/park/provideParkHook";
import {
  EnhancedTableHead,
  EnhancedTableToolbar,
  getComparator,
  HeadCell,
  Order,
  stableSort,
} from "../../core/utils/TableSort";

interface Data {
  id: string;
  name: string;
  disable: boolean;
  category: {
    slug: string;
    icon: string;
    type: string;
  };
  thumbnail: string;
  donations: {
    donations: number;
    goal: number;
    currency: string;
  };
  progress: number;
}

function createData(
  id: string,
  name: string,
  disable = false,
  category: {
    slug: string;
    icon: string;
    type: string;
  },
  thumbnail: string,
  donations: {
    donations: number;
    goal: number;
    currency: string;
  },
  progress: number
): Data {
  return {
    id,
    name,
    disable,
    category,
    thumbnail,
    donations,
    progress,
  };
}

const headCells: HeadCell<Data>[] = [
  {
    id: "name",
    numeric: false,
    label: "Animal",
  },
  {
    id: "disable",
    numeric: false,
    label: "Status",
  },
  {
    id: "category",
    numeric: false,
    label: "Catégorie",
  },
  {
    id: "donations",
    numeric: false,
    label: "Donations",
  },
];

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
}));

export default function AnimalList() {
  const parkHook = usePark();
  const navigate = useNavigate();

  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof Data>("name");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [animals, setAnimals] = useState<Data[]>([]);

  useEffect(() => {
    getDocs(query(collection(db, "animals"), where("park.id", "==", parkHook.park.id))).then((docsSnap) => {
      setAnimals(
        docsSnap.docs.map((a: any) =>
          createData(
            a.id,
            a.data().name,
            a.data().disable,
            a.data().category,
            a.data().thumbnail,
            a.data().donations,
            a.data().progress
          )
        )
      );
    });
  }, [parkHook.park?.id]);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    navigate(`/animals/${name}`);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - animals.length) : 0;

  return (
    <Box sx={{ width: "100%" }}>
      <EnhancedTableToolbar
        title="Tous les animaux"
        actions={[
          <Button
            key="add"
            startIcon={<AddIcon />}
            variant="contained"
            onClick={() => navigate("/animals/new")}
            sx={{ width: 200 }}
          >
            Ajouter un animal
          </Button>,
        ]}
      />
      <TableContainer>
        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={"medium"}>
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={animals.length}
            headCells={headCells}
          />
          <TableBody>
            {stableSort(animals, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row: Data, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row.id)}
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id}
                  >
                    <TableCell component="th" id={labelId} scope="row" padding="none">
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar src={row.thumbnail} />
                        </ListItemAvatar>
                        <ListItemText primary={row.name} />
                      </ListItem>
                    </TableCell>
                    <TableCell sx={{ width: "150px" }}>
                      <Chip label={row.disable ? "Désactiver" : "Activer"} color={row.disable ? "error" : "success"} />
                    </TableCell>
                    <TableCell sx={{ width: "150px" }}>{row.category.slug}</TableCell>
                    <TableCell sx={{ width: "200px" }}>
                      {row.donations ? `${row.donations.donations} / ${row.donations.goal} €` : ""}
                      {row.donations && (
                        <BorderLinearProgress
                          color={
                            row.donations.donations >= row.donations.goal
                              ? "success"
                              : row.donations.donations >= row.donations.goal / 2
                              ? "warning"
                              : "error"
                          }
                          variant="determinate"
                          value={
                            row.donations.donations >= row.donations.goal
                              ? 100
                              : ((row.donations?.donations ?? 0) / (row.donations?.goal ?? 0)) * 100
                          }
                        />
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 53 * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 15, 30]}
        component="div"
        count={animals.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}
