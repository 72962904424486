import { ILanguages } from "./languages";

export interface IReward {
  id: string;
  name: ILanguages;
  description: ILanguages;
  prices: RewardPrice[];
  gifts: Gift[];
  campaignId?: string;
}

export interface RewardPrice {
  currency: string;
  min?: number;
  max?: number;
  recurrence: Recurrence;
}

export enum Recurrence {
  none = "none",
  week = "week",
  month = "month",
  year = "year",
}

export interface IGift {
  id: string | null;
  name: ILanguages;
  description: ILanguages;
  qrcode?: boolean;
  consumable?: number;
  isRecurrenceConsumable?: boolean;
  minDayConsumable?: number;
  maxDayConsumable?: number;
  recurrenceConsumable?: Recurrence;
  onlyAnimal?: boolean;
  onlyPark?: boolean;

  // TODO: a faire
  template?: string; // ex: certificat de parrainage
  actions?: any[]; // ex: choisir un cadeau physique
  features?: any[]; // ex: accès aux stories
}

export class Reward implements IReward {
  id: string;
  name: ILanguages;
  description: ILanguages;
  prices: RewardPrice[];
  gifts: Gift[];
  campaignId?: string;

  constructor(
    id = "",
    name = { fr: "", en: "" },
    description = { fr: "", en: "" },
    prices = [],
    gifts = [],
    campaignId?: string
  ) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.prices = prices;
    this.gifts = gifts;
    this.campaignId = campaignId;
  }

  static errors(reward: IReward) {
    const errors = [];
    if (reward.name.fr === "" || reward.name.en === "") {
      errors.push({
        name: "name",
        message: "Le nom est obligatoire",
      });
    }
    if (reward.description.fr === "" || reward.description.en === "") {
      errors.push({
        name: "description",
        message: "La description est obligatoire",
      });
    }
    if (reward.prices.length === 0) {
      errors.push({
        name: "prices",
        message: "Vous devez ajouter au moins un prix",
      });
    }
    if (reward.gifts.length === 0) {
      errors.push({
        name: "gifts",
        message: "Vous devez ajouter au moins une contrepartie",
      });
    }

    return errors;
  }
}

export class Gift implements IGift {
  id: string;
  name: ILanguages;
  description: ILanguages;
  qrcode?: boolean;
  consumable?: number;
  isRecurrenceConsumable?: boolean;
  minDayConsumable?: number;
  maxDayConsumable?: number;
  recurrenceConsumable?: Recurrence;
  onlyAnimal?: boolean;
  onlyPark?: boolean;

  // TODO: a faire
  template?: string; // ex: certificat de parrainage
  actions?: any[]; // ex: choisir un cadeau physique
  features?: any[]; // ex: accès aux stories

  constructor(
    id = "",
    name = { fr: "", en: "" },
    description = { fr: "", en: "" },
    qrcode?: boolean,
    consumable?: number,
    isRecurrenceConsumable?: boolean,
    minDayConsumable?: number,
    maxDayConsumable?: number,
    recurrenceConsumable?: Recurrence,
    onlyAnimal?: boolean,
    onlyPark?: boolean
  ) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.qrcode = qrcode;
    this.consumable = consumable;
    this.isRecurrenceConsumable = isRecurrenceConsumable;
    this.minDayConsumable = minDayConsumable;
    this.maxDayConsumable = maxDayConsumable;
    this.recurrenceConsumable = recurrenceConsumable;
    this.onlyAnimal = onlyAnimal;
    this.onlyPark = onlyPark;
  }

  static errors(gift: IGift) {
    const errors = [];
    if (gift.name.fr === "") {
      errors.push({
        name: "name.fr",
        message: "Le nom est obligatoire",
      });
    }
    if (gift.description.fr === "") {
      errors.push({
        name: "description.fr",
        message: "La description est obligatoire",
      });
    }

    if (gift.name.en === "") {
      errors.push({
        name: "name.en",
        message: "Le nom est obligatoire",
      });
    }
    if (gift.description.en === "") {
      errors.push({
        name: "description.en",
        message: "La description est obligatoire",
      });
    }

    return errors;
  }
}
