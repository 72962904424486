export enum Type {
  Animal = "animal",
  Park = "park",
}

export interface ICategory {
  id: string;
  icon?: string;
  main: boolean;
  slug: string;
  thumbnail: string;
  animalsCounter?: number;
  parksCounter?: number;
  parents?: string[];
  children?: string[];
  type?: Type;
}

export class Category implements ICategory {
  id: string;
  icon: string;
  main: boolean;
  slug: string;
  thumbnail: string;
  animalsCounter?: number;
  parksCounter?: number;
  parents?: string[];
  children?: string[];
  type?: Type;

  constructor(id = "", icon = "", main = false, slug = "", type = undefined, thumbnail = "", parents = [], children = []) {
    this.id = id;
    this.icon = icon;
    this.main = main;
    this.slug = slug;
    this.thumbnail = thumbnail;
    this.type = type;
    this.parents = parents;
    this.children = children;
  }

  static errors(category: ICategory) {
    const errors = [];
    if (category.slug === "") {
      errors.push({
        name: "slug",
        message: "Le slug est obligatoire",
      });
    }
    if (category.icon === "") {
      errors.push({
        name: "icon",
        message: "Le icon est obligatoire",
      });
    }
    if (!category.type) {
      errors.push({
        name: "type",
        message: "Le type est obligatoire",
      });
    }

    return errors;
  }
}
