import DeleteIcon from "@mui/icons-material/Delete";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemText,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IAnimal } from "../../core/models/animal";
import * as AnimalService from "../../core/services/animal-service";
import { DonationPage } from "./donation/DonationPage";
import { RewardPage } from "./reward/RewardPage";
import StoryList from "./StoryList";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
}));

export function AnimalShow() {
  const { animalId } = useParams();
  const [tab, setTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [nameConfirmDelete, setNameConfirmDelete] = useState("");
  const [errorConfirm, setErrorConfirm] = useState<string | undefined>();

  const [animal, setAnimal] = useState<IAnimal>();

  const navigate = useNavigate();

  const handleChangeTab = (event: any, newValue: number) => {
    setTab(newValue);
  };

  const handleDelete = (event: any) => {
    if (nameConfirmDelete.toLowerCase() === animal?.name.toLowerCase()) {
      setErrorConfirm(undefined);
      setLoading(true);
      AnimalService.remove(animal.id)
        .then(() => {
          setLoading(false);
          navigate(`/animals`);
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      setErrorConfirm("Le nom de l'animal n'est pas identique.");
    }
  };

  const handleCloseDelete = () => {
    setErrorConfirm(undefined);
    setNameConfirmDelete("");
    setOpenConfirmDelete(false);
  };

  const getAnimal = useCallback(() => {
    if (animalId) {
      AnimalService.get(animalId)
        .then((a: IAnimal) => {
          setAnimal(a);
        })
        .catch(() => {
          navigate("/animals");
        });
    } else {
      navigate("/animals");
    }
  }, [navigate, animalId]);

  useEffect(() => {
    getAnimal();
  }, [getAnimal]);

  if (!animal) {
    return <CircularProgress />;
  }

  return (
    <>
      {openConfirmDelete && (
        <Dialog open={openConfirmDelete} onClose={handleCloseDelete}>
          <DialogTitle>Êtes-vous VRAIMENT certains de vouloir supprimer "{animal.name}" ?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Cette action irrémédiable va <b>SUPPRIMER</b> l'animal, images, rewards, stories et ses donations.
            </DialogContentText>
            <TextField
              autoFocus
              value={nameConfirmDelete}
              margin="dense"
              label={`Confirmer le nom d'animal: ${animal.name}`}
              fullWidth
              variant="standard"
              error={!!errorConfirm}
              helperText={errorConfirm}
              onChange={(event) => {
                setErrorConfirm("");
                setNameConfirmDelete(event.target.value);
              }}
            />
          </DialogContent>
          <DialogActions>
            <LoadingButton loading={loading} onClick={handleCloseDelete}>
              Annuler
            </LoadingButton>
            <LoadingButton
              color="error"
              disabled={loading || nameConfirmDelete.toLowerCase() !== animal.name.toLowerCase()}
              onClick={handleDelete}
              loading={loading}
              loadingPosition="start"
              startIcon={<DeleteIcon />}
              variant="contained"
            >
              Confirmer
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}
      <Box component="main" sx={{ flex: 1, pt: 2, pb: 6, px: 4, bgcolor: "#eaeff1" }}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Card>
              <CardMedia component="img" height="250" image={animal.thumbnail} alt={animal.name} />
              <CardContent>
                <Typography variant="h5" component="div" align="center">
                  {animal.name}
                </Typography>
                <br />
                {animal.donations && (
                  <Typography variant="caption" component="div" align="center">
                    {animal.donations.donations ?? 0} € / {animal.donations.goal ?? 0} €
                  </Typography>
                )}
                {animal.donations && (
                  <BorderLinearProgress
                    color={
                      animal.donations.donations >= (animal.donations.goal || 0)
                        ? "success"
                        : animal.donations.donations >= (animal.donations.goal || 0) / 2
                        ? "warning"
                        : "error"
                    }
                    variant="determinate"
                    value={
                      animal.donations.donations >= (animal.donations.goal || 0)
                        ? 100
                        : ((animal.donations?.donations ?? 0) / (animal.donations?.goal ?? 0)) * 100
                    }
                  />
                )}
                <List>
                  <ListItem alignItems="flex-start">
                    <ListItemText primary="Date de naissance" secondary={animal.birthDate} />
                  </ListItem>
                  <ListItem alignItems="flex-start">
                    <ListItemText primary="Lieu de naissance" secondary={animal.birthPlace} />
                  </ListItem>
                  <ListItem alignItems="flex-start">
                    <ListItemText primary="Catégorie" secondary={animal.category.slug} />
                  </ListItem>
                  <ListItem alignItems="flex-start">
                    <ListItemText primary="Espèce" secondary={animal.type.fr} />
                  </ListItem>
                </List>
              </CardContent>
              <CardActions sx={{ justifyContent: "center" }}>
                <Button
                  variant="text"
                  color="error"
                  onClick={() => {
                    setOpenConfirmDelete(true);
                  }}
                >
                  Supprimer
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    navigate(`/animals/${animalId}/edit`);
                  }}
                >
                  Editer
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={9}>
            <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}>
              <Tabs value={tab} onChange={handleChangeTab}>
                <Tab label="Dashboard" />
                <Tab label="Dons" />
                <Tab label="Stories" />
                <Tab label="Contreparties" />
                <Tab label="Followers" />
              </Tabs>
            </Box>
            <Box>
              <div role="tabpanel" hidden={tab !== 0} id={`nav-tab-${0}`} aria-labelledby={`nav-tab-${0}`}></div>

              <div role="tabpanel" hidden={tab !== 1} id={`nav-tab-${1}`} aria-labelledby={`nav-tab-${1}`}>
                <Card>
                  <CardContent>{animalId && <DonationPage animal={animal} refresh={() => getAnimal()} />}</CardContent>
                </Card>
              </div>
              <div role="tabpanel" hidden={tab !== 2} id={`nav-tab-${2}`} aria-labelledby={`nav-tab-${2}`}>
                {animal && <StoryList animal={animal} />}
              </div>
              <div role="tabpanel" hidden={tab !== 3} id={`nav-tab-${3}`} aria-labelledby={`nav-tab-${3}`}>
                <Card>
                  <CardContent>{animal && <RewardPage animal={animal} />}</CardContent>
                </Card>
              </div>
              <div role="tabpanel" hidden={tab !== 4} id={`nav-tab-${4}`} aria-labelledby={`nav-tab-${4}`}>
                <Card>
                  <CardContent>
                    <List>
                      <ListItem></ListItem>
                    </List>
                    Followers list
                  </CardContent>
                </Card>
              </div>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
