import { FormControl, FormHelperText, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { ILanguages, Languages } from "../models/languages";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 2 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface InputLanguagesProps {
  label: string;
  key: string;
  required?: boolean;
  value?: ILanguages;
  onChange: (value: ILanguages) => void;
  error?: { name: string; message: string };
}

export default function InputLanguages(props: InputLanguagesProps) {
  const [tab, setTab] = React.useState(0);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const handleChangeFr = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const valueClone = props.value ?? new Languages("", "");
    valueClone.fr = event.target.value;
    props.onChange(valueClone);
  };

  const handleChangeEn = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const valueClone = props.value ?? new Languages("", "");
    valueClone.en = event.target.value;
    props.onChange(valueClone);
  };

  const getError = (language: string) => {
    if (!props.error) {
      return null;
    }
    if (props.error.name === props.key) {
      return props.error.message;
    }
    const split = props.error.name.split(".");
    if (split.length > 2 && split[0] === props.key && split[1] === language) {
      return props.error.message;
    }
    return null;
  };

  return (
    <Box sx={{ width: "100%", mt: 2 }}>
      <Typography>{props.label}</Typography>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={tab} onChange={handleChangeTab}>
          <Tab label="Français" {...a11yProps(0)} key={`${props.key}-fr`} />
          <Tab label="Anglais" {...a11yProps(1)} key={`${props.key}-en`} />
        </Tabs>
      </Box>
      <TabPanel value={tab} index={0}>
        <FormControl fullWidth error={!!getError("fr")}>
          <TextField
            key={`${props.key}-fr-input`}
            fullWidth
            variant="outlined"
            label={`Fr - ${props.label}`}
            multiline
            error={!!props.error}
            rows={4}
            value={props.value?.fr ?? ""}
            onChange={handleChangeFr}
          />
          <FormHelperText>{getError("fr")}</FormHelperText>
        </FormControl>
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <FormControl fullWidth error={!!getError("en")}>
          <TextField
            key={`${props.key}-en-input`}
            fullWidth
            variant="outlined"
            label={`En - ${props.label}`}
            multiline
            error={!!props.error}
            rows={4}
            value={props.value?.en ?? ""}
            onChange={handleChangeEn}
          />
          <FormHelperText>{getError("en")}</FormHelperText>
        </FormControl>
      </TabPanel>
    </Box>
  );
}
