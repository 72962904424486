export enum StoryType {
  'image' = 'image',
  'video' = 'video'
}

export interface Story {
  id: string;
  createdAt: Date;
  postedAt: Date;
  type: StoryType;
  url: string;
  content: {
    position: {
      left: number;
      top: number;
    };
    text: string;
    textStyle: {
      transform: string;
      color: string;
      size: number;
      weight: string;
    };
  }[];
}