import { CircularProgress, Typography } from '@mui/material';
import { LoginDialog } from "../modules/authentication/LoginDialog";
import { useAuth } from "./provideAuthHook";

export const PrivateRoute = (props: any) => {
  let auth = useAuth();

  if (auth.loading) {
    return <CircularProgress />;
  }

  if (!auth.user) {
    return (
      <LoginDialog open={true} onClose={() => {
        
      }}/>
    );
  }
  
  if (props.superAdmin && !auth.superAdmin) {
    return <Typography variant="h2" >Vous n'avez pas accès à cette section.</Typography>
  }

  return props.children;
}