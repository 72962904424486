import ClearIcon from "@mui/icons-material/Clear";
import {
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Recurrence, RewardPrice } from "../../../core/models/reward";

interface RewardPriceComponentProps extends RewardPrice {
  errors?: { name: string; message: string }[];
  onSubmit: (price: RewardPrice) => void;
  onDelete: () => void;
}

export function RewardPriceComponent(props: RewardPriceComponentProps) {
  const [min, setMin] = useState<number>();
  const [max, setMax] = useState<number>();
  const [recurrence, setRecurrence] = useState<Recurrence>(Recurrence.none);
  const [currency, setCurrency] = useState<string>("eur");

  const handleChangeRecurrence = (event: SelectChangeEvent) => {
    props.onSubmit({
      min,
      max,
      recurrence: event.target.value as Recurrence,
      currency,
    });
  };

  const handleChangeMin = (event: any) => {
    props.onSubmit({
      min: event.currentTarget.value !== "" ? +event.currentTarget.value : undefined,
      max,
      recurrence,
      currency,
    });
  };

  const handleChangeMax = (event: any) => {
    props.onSubmit({
      min,
      max: event.currentTarget.value !== "" ? +event.currentTarget.value : undefined,
      recurrence,
      currency,
    });
  };

  useEffect(() => {
    if (props) {
      setMin(props.min);
      setMax(props.max);
      setRecurrence(props.recurrence);
      setCurrency(props.currency);
    }
  }, [props]);

  return (
    <Grid container spacing={2} sx={{ alignItems: "center" }}>
      <Grid item xs={3}>
        <FormControl>
          <InputLabel htmlFor="min-input">Minimum</InputLabel>
          <OutlinedInput
            value={min}
            onChange={handleChangeMin}
            type="number"
            endAdornment={<InputAdornment position="end">€</InputAdornment>}
            id="min-input"
            label="Minimum"
          />
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl>
          <InputLabel htmlFor="max-input">Maximum</InputLabel>
          <OutlinedInput
            value={max}
            onChange={handleChangeMax}
            type="number"
            endAdornment={<InputAdornment position="end">€</InputAdornment>}
            id="max-input"
            label="Maximum"
          />
        </FormControl>
      </Grid>
      <Grid item xs={5}>
        <FormControl fullWidth>
          <InputLabel id="recurrence-select-label">Récurrence</InputLabel>
          <Select
            labelId="recurrence-select-label"
            id="recurrence-select"
            value={recurrence}
            label="Récurrence"
            onChange={handleChangeRecurrence}
          >
            <MenuItem value="none">Don en une fois</MenuItem>
            <MenuItem value="week">Don hebdomadaire</MenuItem>
            <MenuItem value="month">Don mensuel</MenuItem>
            <MenuItem value="year">Don annuel</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={1}>
        <IconButton color="error" aria-label="Delete price" onClick={props.onDelete} sx={{ float: "right" }}>
          <ClearIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
}
