import EditIcon from "@mui/icons-material/Edit";
import { Box, IconButton, ImageList } from "@mui/material";
import { styled } from "@mui/material/styles";
import Typography, { TypographyProps } from "@mui/material/Typography";
import update from "immutability-helper";
import { Fragment, useCallback, useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDropzone } from "react-dropzone";
import { Image } from "../models/image";
import { FileDrag } from "./FileDrag";

const HeadingTypography = styled(Typography)<TypographyProps>(({ theme }) => ({
  marginBottom: theme.spacing(5),
  [theme.breakpoints.down("sm")]: {
    marginBottom: theme.spacing(4),
  },
}));

export interface IFile {
  file?: File;
  url?: string;
  alt?: string;
  delete?: boolean;
  image?: Image;
  other?: any;
}

interface FileUploaderMultipleProps {
  files: IFile[];
  label?: string;
  withAlt?: boolean;
  multiple?: boolean;
  onChange: (files: IFile[]) => void;
}

const FileUploaderMultiple = (props: FileUploaderMultipleProps) => {
  const [files, setFiles] = useState<IFile[]>([]);

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: props.multiple ? undefined : 1,
    onDrop: (acceptedFiles: File[]) => {
      let newFiles = [];
      if (props.multiple) {
        const filesAdded = acceptedFiles.map(
          (file: File) => ({ file: Object.assign(file), alt: "", url: URL.createObjectURL(file as any) } as IFile)
        );
        newFiles = [...filesAdded, ...files];
      } else {
        newFiles = acceptedFiles.map(
          (file: File) => ({ file: Object.assign(file), alt: "", url: URL.createObjectURL(file) } as IFile)
        );
      }
      props.onChange(newFiles);
    },
  });

  const handleRemoveFile = (indexFile: number) => {
    const uploadedFiles = [...files];
    uploadedFiles[indexFile].delete = true;
    const file = uploadedFiles[indexFile].file;
    if (file) {
      if (uploadedFiles[indexFile].image) {
        uploadedFiles[indexFile].file = undefined;
      } else {
        uploadedFiles.splice(indexFile, 1);
      }
    }
    props.onChange(uploadedFiles);
  };

  const handleChangeAlt = (value: string, indexFile: number) => {
    const uploadedFiles = [...files];
    uploadedFiles[indexFile].alt = value;
    props.onChange(uploadedFiles);
  };

  const moveFile = useCallback((dragIndex: number, hoverIndex: number) => {
    setFiles((prevFiles: IFile[]) =>
      update(prevFiles, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevFiles[dragIndex] as IFile],
        ],
      })
    );
  }, []);

  useEffect(() => {
    setFiles(props.files);
  }, [props.files]);

  if (!props.multiple) {
    if (files.length > 0) {
      return (
        <Fragment>
          {props.label && <Typography>{props.label}</Typography>}
          <Box
            {...getRootProps({ className: "dropzone" })}
            sx={{
              p: 2,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundImage: `url('${files[0].url ?? files[0].image?.src}') `,
            }}
          >
            <input {...getInputProps()} multiple={false} />
            <Box
              sx={{
                display: "flex",
                flexDirection: ["column"],
                alignItems: "center",
                height: 250,
                justifyContent: "center",
              }}
            >
              <IconButton aria-label="edit" size="large">
                <EditIcon fontSize="inherit" sx={{ color: "white" }} />
              </IconButton>
            </Box>
          </Box>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          {props.label && <Typography>{props.label}</Typography>}
          <Box {...getRootProps({ className: "dropzone" })} sx={{ border: "grey 2px dashed", p: 2 }}>
            <input {...getInputProps()} />
            <Box sx={{ display: "flex", flexDirection: ["column", "column", "row"], alignItems: "center" }}>
              <Box sx={{ display: "flex", flexDirection: "column", textAlign: ["center", "center", "inherit"] }}>
                <HeadingTypography variant="h5" align="center">
                  Drop ou clique pour télécharger ton image
                </HeadingTypography>
              </Box>
            </Box>
          </Box>
        </Fragment>
      );
    }
  }

  return (
    <Fragment>
      {props.label && <Typography>{props.label}</Typography>}
      <Box {...getRootProps({ className: "dropzone" })} sx={{ border: "grey 2px dashed", p: 2 }}>
        <input {...getInputProps()} />
        <Box sx={{ display: "flex", flexDirection: ["column", "column", "row"], alignItems: "center" }}>
          <Box sx={{ display: "flex", flexDirection: "column", textAlign: ["center", "center", "inherit"] }}>
            <HeadingTypography variant="h5" align="center">
              +
            </HeadingTypography>
          </Box>
        </Box>
      </Box>
      {files.length ? (
        <Fragment>
          <DndProvider backend={HTML5Backend}>
            <ImageList>
              {files
                .filter((file: IFile) => !file.delete)
                .map((file: IFile, index: number) => (
                  <FileDrag
                    key={index}
                    file={file}
                    index={index}
                    moveFile={moveFile}
                    removeFile={handleRemoveFile}
                    changeAlt={handleChangeAlt}
                  />
                ))}
            </ImageList>
          </DndProvider>
        </Fragment>
      ) : null}
    </Fragment>
  );
};

export default FileUploaderMultiple;
