import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import * as React from 'react';
import { useMatch, useNavigate, useResolvedPath } from 'react-router-dom';

const item = {
  py: '2px',
  px: 3,
  color: 'rgba(255, 255, 255, 0.7)',
  '&:hover, &:focus': {
    bgcolor: 'rgba(255, 255, 255, 0.08)',
  },
};

export default function NavigatorItem(props: any) {
  let navigate = useNavigate();
  let resolved = useResolvedPath(props.to);
  let match = useMatch({ path: resolved.pathname, end: true });

  const handleClick = () => {
    navigate(props.to);
  }

  return (
    <ListItem disablePadding>
      <ListItemButton selected={!!match} sx={item} onClick={handleClick}>
        <ListItemIcon>{props.icon}</ListItemIcon>
        <ListItemText>{props.id}</ListItemText>
      </ListItemButton>
    </ListItem>
  );
}