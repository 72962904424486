import { useProvidePark, parkContext } from "./provideParkHook";

interface PropsProviderPark {
  children: any
}

export const ProvidePark = (props: PropsProviderPark) => {
  const park = useProvidePark();
  return (
    <parkContext.Provider value={park}>
      {props.children}
    </parkContext.Provider>
  );
};

