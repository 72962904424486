import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";

import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { documentId, where } from "firebase/firestore";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ADMIN_CATEGORY_EDIT, ADMIN_CATEGORY_LIST, ADMIN_CATEGORY_SHOW } from "../../../config/routes";
import ComponentAnimalList from "../../../core/components/AnimalList";
import { IAnimal } from "../../../core/models/animal";
import { ICategory, Type } from "../../../core/models/category";
import { IPark } from "../../../core/models/park";
import * as AnimalService from "../../../core/services/animal-service";
import * as CategoryService from "../../../core/services/category-service";
import * as ParkService from "../../../core/services/park-service";
import { EnhancedTableToolbar } from "../../../core/utils/TableSort";

export function CategoryShow() {
  const { categoryId } = useParams();

  const [tab, setTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [slugConfirmDelete, setSlugConfirmDelete] = useState("");
  const [errorConfirm, setErrorConfirm] = useState<string | undefined>();

  const [category, setCategory] = useState<ICategory>();
  const [objectsLinked, setObjectsLinked] = useState<IAnimal[] | IPark[]>([]);
  const [parentsChildren, setParentsChildren] = useState<ICategory[]>([]);

  const navigate = useNavigate();

  const handleChangeTab = (event: any, newValue: number) => {
    setTab(newValue);
  };

  const handleDelete = (event: any) => {
    if (slugConfirmDelete === category?.slug) {
      setErrorConfirm(undefined);
      setLoading(true);
      // deleteCategory(category.id).then(() => {
      //   setLoading(false);
      //   navigate(`/admin/categories`);
      // }).catch((err) => {
      //   setLoading(false);
      // });
    } else {
      setErrorConfirm("Le slug n'est pas identique.");
    }
  };

  const handleCloseDelete = () => {
    setErrorConfirm(undefined);
    setSlugConfirmDelete("");
    setOpenConfirmDelete(false);
  };

  const getCategory = useCallback(() => {
    if (categoryId) {
      setObjectsLinked([]);
      setParentsChildren([]);
      CategoryService.get(categoryId)
        .then((c: ICategory) => {
          setCategory(c);
          if (c.type === Type.Animal && !c.main) {
            AnimalService.all(where("category.id", "==", c.id)).then((a: IAnimal[]) => {
              setObjectsLinked(a);
            });
          } else if (c.type === Type.Park && !c.main) {
            ParkService.all(where("category.id", "==", c.id)).then((p: IPark[]) => {
              setObjectsLinked(p);
            });
          }
          if (c.parents?.length) {
            CategoryService.all(where(documentId(), "in", c.parents)).then((pc: ICategory[]) => {
              setParentsChildren(pc);
            });
          } else if (c.children?.length) {
            CategoryService.all(where(documentId(), "in", c.children)).then((pc: ICategory[]) => {
              setParentsChildren(pc);
              if (pc.length > 0) {
                AnimalService.all(where("category.id", "in", c.children)).then((a: IAnimal[]) => {
                  setObjectsLinked([...(objectsLinked as IAnimal[]), ...a]);
                });
              }
            });
          }
        })
        .catch(() => {
          navigate(ADMIN_CATEGORY_LIST);
        });
    } else {
      navigate(ADMIN_CATEGORY_LIST);
    }
  }, [navigate, categoryId]);

  useEffect(() => {
    getCategory();
  }, [getCategory]);

  if (!category) {
    return <CircularProgress />;
  }

  return (
    <>
      {openConfirmDelete && (
        <Dialog open={openConfirmDelete} onClose={handleCloseDelete}>
          <DialogTitle>Êtes-vous VRAIMENT certains de vouloir supprimer "{category.slug}" ?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Cette action irrémédiable va <b>SUPPRIMER</b> l'utilisateur et tout son contenu.
            </DialogContentText>
            <TextField
              autoFocus
              value={slugConfirmDelete}
              margin="dense"
              label={`Confirmer le slug de la catégorie: ${category.slug}`}
              fullWidth
              variant="standard"
              error={!!errorConfirm}
              helperText={errorConfirm}
              onChange={(event) => {
                setErrorConfirm("");
                setSlugConfirmDelete(event.target.value);
              }}
            />
          </DialogContent>
          <DialogActions>
            <LoadingButton loading={loading} onClick={handleCloseDelete}>
              Annuler
            </LoadingButton>
            <LoadingButton
              color="error"
              disabled={loading || slugConfirmDelete !== category.slug}
              onClick={handleDelete}
              loading={loading}
              loadingPosition="start"
              startIcon={<DeleteIcon />}
              variant="contained"
            >
              Confirmer
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}
      <Box component="main" sx={{ flex: 1, pt: 2, pb: 6, px: 4, bgcolor: "#eaeff1" }}>
        <IconButton aria-label="back" sx={{ mb: 2 }} onClick={() => navigate("/admin/categories")}>
          <ArrowBackIcon />
        </IconButton>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Card>
              <CardHeader
                avatar={<Avatar sx={{ width: 45, height: 45 }} src={category.thumbnail}></Avatar>}
                title={
                  <Typography variant="h5">
                    {category.id}: {category.slug}
                  </Typography>
                }
              />
              <CardContent>
                <List>
                  <ListItem alignItems="flex-start">
                    <ListItemText primary="Principal" secondary={category.main ? "Oui" : "Non"} />
                  </ListItem>
                  <ListItem alignItems="flex-start">
                    <ListItemText primary="Type" secondary={category.type} />
                  </ListItem>
                  <ListItem alignItems="flex-start">
                    <ListItemText primary="Slug" secondary={category.slug} />
                  </ListItem>
                  <ListItem alignItems="flex-start">
                    <Avatar sx={{ width: 50, height: 50 }} src={category.icon}></Avatar>
                    <ListItemText primary="Icon" secondary={category.icon} />
                  </ListItem>
                  <ListItem alignItems="flex-start">
                    <ListItemText primary="Animaaux" secondary={category.animalsCounter} />
                  </ListItem>
                  <ListItem alignItems="flex-start">
                    <ListItemText primary="Parent" secondary={category.parents?.length} />
                  </ListItem>
                </List>
              </CardContent>
              <CardActions sx={{ justifyContent: "center" }}>
                <Button
                  variant="text"
                  color="error"
                  onClick={() => {
                    setOpenConfirmDelete(true);
                  }}
                >
                  Supprimer
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    navigate(ADMIN_CATEGORY_EDIT.replace(":categoryId", category.id));
                  }}
                >
                  Editer
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={9}>
            <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}>
              <Tabs value={tab} onChange={handleChangeTab}>
                <Tab label="Dashboard" />
                <Tab label={category?.type === Type.Park ? "Parcs" : "Animaux"} />
                <Tab label={category?.main ? "Catégories enfants" : "Catégories parents"} />
              </Tabs>
            </Box>
            <Box>
              <div
                role="tabpanel"
                hidden={tab !== 0}
                id={`simple-tabpanel-${0}`}
                aria-labelledby={`simple-tab-${0}`}
              ></div>
              <div role="tabpanel" hidden={tab !== 1} id={`simple-tabpanel-${1}`} aria-labelledby={`simple-tab-${1}`}>
                <ComponentAnimalList
                  animals={objectsLinked as IAnimal[]}
                  toolbar={
                    <EnhancedTableToolbar
                      title={`Tous les ${category.type === Type.Animal ? "animaux" : "parcs"} relié à la catégorie`}
                      actions={[
                        <Button
                          key="add"
                          startIcon={<AddIcon />}
                          variant="contained"
                          onClick={() => {}}
                          sx={{ width: 200 }}
                        >
                          Ajouter un animal
                        </Button>,
                      ]}
                    />
                  }
                />
              </div>
              <div role="tabpanel" hidden={tab !== 2} id={`simple-tabpanel-${2}`} aria-labelledby={`simple-tab-${2}`}>
                <TableContainer>
                  <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={"small"}>
                    <TableBody>
                      {parentsChildren.map((row: any, index) => {
                        return (
                          <TableRow
                            hover
                            onClick={(event) => {
                              navigate(ADMIN_CATEGORY_SHOW.replace(":categoryId", row.id));
                            }}
                            key={row.id}
                          >
                            <TableCell component="th" scope="row">
                              <ListItem>
                                <ListItemAvatar>
                                  <Avatar sx={{ width: 50, height: 50 }} src={row.thumbnail}></Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={row.slug} />
                              </ListItem>
                            </TableCell>
                            <TableCell sx={{ width: "150px" }}>
                              {row.main === true ? "Oui" : row.main === false ? "Non" : ""}
                            </TableCell>
                            <TableCell sx={{ width: "150px" }}>{row.type}</TableCell>
                            <TableCell sx={{ width: "170px", textAlign: "center" }}>
                              {row.animalsCounter ?? row.parksCounter}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
