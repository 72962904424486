import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { MobileDateTimePicker } from "@mui/x-date-pickers";
import { add } from "date-fns";
import { Fragment, useEffect, useState } from "react";
import InputLanguages from "../../../core/components/InputLanguages";
import { Campaign } from "../../../core/models/campaign";
import { ILanguages } from "../../../core/models/languages";

interface DonationNewCampaignProps {
  open: boolean;
  campaign?: any;
  onClose: (campaign?: Campaign) => void;
  disabledDates: Date[];
}

export function DonationNewCampaign(props: DonationNewCampaignProps) {
  const [submitted, setSubmitted] = useState(false);
  const [campaign, setCampaign] = useState<any>({
    name: {
      fr: "",
      en: "",
    },
    description: {
      fr: "",
      en: "",
    },
    startedAt: null,
    endAt: null,
    donations: {
      goal: null,
      donations: 0,
      currency: "eur",
    },
  });

  const handleChangeGoal = (event: any) => {
    setCampaign({
      ...campaign,
      donations: {
        ...campaign.donations,
        goal: event.currentTarget.value !== "" ? +event.currentTarget.value : null,
      },
    });
  };

  const handleChangeName = (value: ILanguages) => {
    setCampaign({
      ...campaign,
      name: value,
    });
  };

  const handleChangeDescription = (value: ILanguages) => {
    setCampaign({
      ...campaign,
      description: value,
    });
  };

  const handleChangeStartedAt = (date: Date | null) => {
    setCampaign({
      ...campaign,
      startedAt: date,
      endAt: campaign.endAt && date && date > campaign.endAt ? null : campaign.endAt,
    });
  };

  const handleChangeEndAt = (date: Date | null) => {
    setCampaign({
      ...campaign,
      endAt: date,
    });
  };

  const handleDisabledDate = (date: Date) => {
    return props.disabledDates.map((d: Date) => d.getTime()).includes(date.getTime());
  };

  const handleSubmit = () => {
    setSubmitted(true);
    if (
      campaign.name.fr !== "" &&
      campaign.name.en !== "" &&
      campaign.description.fr !== "" &&
      campaign.description.en !== "" &&
      campaign.donations.goal > 0 &&
      campaign.startedAt &&
      campaign.endAt
    ) {
      props.onClose(campaign);
    }
  };

  useEffect(() => {
    if (props.campaign) {
      setCampaign(props.campaign);
    }
  }, [props.campaign]);

  return (
    <Fragment>
      <Dialog
        fullWidth
        maxWidth="md"
        open={props.open}
        onClose={() => props.onClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>Nouvelle campagne</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <FormControl sx={{ mt: 2 }} fullWidth error={submitted && !campaign.startedAt}>
                <MobileDateTimePicker
                  disablePast
                  inputFormat="dd/MM/yyyy HH:mm"
                  shouldDisableDate={handleDisabledDate}
                  mask="___/__/__ __:__"
                  label="Commence le"
                  value={campaign.startedAt}
                  onChange={handleChangeStartedAt}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      error={submitted && !campaign.startedAt}
                      helperText={submitted && !campaign.startedAt ? "La date est obligatoire" : null}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl sx={{ mt: 2 }} fullWidth error={submitted && !campaign.endAt}>
                <MobileDateTimePicker
                  minDateTime={add(campaign.startedAt, { days: 1 })}
                  label="Fini le"
                  inputFormat="dd/MM/yyyy HH:mm"
                  shouldDisableDate={handleDisabledDate}
                  mask="___/__/__ __:__"
                  disabled={!campaign.startedAt}
                  value={campaign.endAt}
                  onChange={handleChangeEndAt}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      error={submitted && !campaign.endAt}
                      helperText={submitted && !campaign.endAt ? "La date est obligatoire" : null}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>

          <FormControl sx={{ mt: 2 }}>
            <InputLabel htmlFor="outlined-adornment-amount">Objectif</InputLabel>
            <OutlinedInput
              value={campaign.donations.goal?.toString() ?? ""}
              onChange={handleChangeGoal}
              type="number"
              endAdornment={<InputAdornment position="end">€</InputAdornment>}
              id="outlined-basic"
              label="Outlined"
            />
          </FormControl>

          <InputLanguages label="Titre" required key="name" value={campaign.name} onChange={handleChangeName} />

          <InputLanguages
            label="Description"
            required
            key="description"
            value={campaign.description}
            onChange={handleChangeDescription}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => props.onClose()} variant="text">
            Annuler
          </Button>
          <Button onClick={handleSubmit} variant="contained">
            {campaign.id ? "Modifier" : "Créer"}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
