import styled from '@emotion/styled'
import CloseIcon from '@mui/icons-material/Close'
import { Card, ImageListItem, ImageListItemBar, TextField, TextFieldProps, Typography } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import type { Identifier, XYCoord } from 'dnd-core'
import type { FC } from 'react'
import { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { IFile } from './FileUploader'

const TextFieldImage = styled(TextField)<TextFieldProps>(({ theme }) => ({
  input: {
    color: 'white',
    paddingBottom: 0,
    paddingTop: 0
  },
  '& label': {
    color: 'white'
  },
  '& label.Mui-focused': {
    color: 'white'
  },
  '& .MuiFilledInput-root:before': {
    borderColor: 'white'
  }
}))

export interface FileProps {
  file: IFile;
  index: number
  moveFile: (dragIndex: number, hoverIndex: number) => void;
  changeAlt: (value: string, index: number) => void;
  removeFile: (index: number) => void;
}

interface DragItem {
  index: number
  id: string
  type: string
}

export const FileDrag: FC<FileProps> = ({ file, index, moveFile, changeAlt, removeFile }) => {
  const ref = useRef<HTMLDivElement>(null)
  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: 'card',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveFile(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: 'card',
    item: () => {
      return { index }
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const opacity = isDragging ? 0 : 1
  drag(drop(ref))
  return (
    <div ref={ref} style={{ opacity }} data-handler-id={handlerId}>
      <Card key={index}>
        <ImageListItem key={index}>
          <img width={150} height={150} alt={file.alt !== '' ? file.alt : file.image?.alt} src={file.url ?? file.image?.src} />
          <ImageListItemBar
            title={<TextFieldImage
              fullWidth
              variant="filled"
              placeholder="Nom"
              name="name"
              size="small"
              id={`image-${index}`}
              value={file.alt !== '' ? file.alt : file.image?.alt}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                changeAlt(event.target.value, index);
                // const filesClone = [...files];
                // filesClone[index].alt = event.target.value;
                // if (filesClone[index].image) {
                //   (filesClone[index] as any).image.alt = event.target.value;
                // }
                // setFiles(filesClone);
              }}
            />}
            actionIcon={
              <IconButton
                sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                onClick={() => removeFile(index)}
              >
                <CloseIcon />
              </IconButton>
            }
          />
        </ImageListItem>
      </Card>
    </div>
  )
}
