import { Route, Routes as RoutesRouter } from "react-router-dom";
import { PrivateRoute } from "../../auth/PrivateRoute";
import * as routes from "../../config/routes";
import { usePark } from "../../core/hooks/park/provideParkHook";
import { CategoryEdit } from "../admin/category/Edit";
import CategoryList from "../admin/category/List";
import { CategoryShow } from "../admin/category/Show";
import ParkList from "../admin/park/List";
import UserList from "../admin/user/List";
import { UserShow } from "../admin/user/Show";
import { AnimalEdit } from "../animal/Edit";
import AnimalList from "../animal/List";
import { AnimalShow } from "../animal/Show";
import { Home } from "../home/Home";
import { ParkEdit } from "../park/Edit";
import { ParkShow } from "../park/Show";

export const Routes = () => {
  const parkHook = usePark();

  return (
    <RoutesRouter>
      <Route
        path={routes.HOME}
        element={
          <PrivateRoute>
            <Home />
          </PrivateRoute>
        }
      />
      <Route
        path={routes.PARK_EDIT}
        element={
          <PrivateRoute>
            <ParkEdit id={parkHook.park?.id} />
          </PrivateRoute>
        }
      />
      <Route
        path={routes.PARK_SHOW}
        element={
          <PrivateRoute>
            <ParkShow />
          </PrivateRoute>
        }
      />
      <Route
        path={routes.ANIMAL_LIST}
        element={
          <PrivateRoute>
            <AnimalList />
          </PrivateRoute>
        }
      />
      <Route
        path={routes.ANIMAL_NEW}
        element={
          <PrivateRoute>
            <AnimalEdit />
          </PrivateRoute>
        }
      />
      <Route
        path={routes.ANIMAL_EDIT}
        element={
          <PrivateRoute>
            <AnimalEdit />
          </PrivateRoute>
        }
      />
      <Route
        path={routes.ANIMAL_SHOW}
        element={
          <PrivateRoute>
            <AnimalShow />
          </PrivateRoute>
        }
      />
      <Route
        path={routes.ADMIN_USER_LIST}
        element={
          <PrivateRoute superAdmin>
            <UserList />
          </PrivateRoute>
        }
      />
      <Route
        path={routes.ADMIN_USER_SHOW}
        element={
          <PrivateRoute superAdmin>
            <UserShow />
          </PrivateRoute>
        }
      />
      <Route
        path={routes.ADMIN_PARK_LIST}
        element={
          <PrivateRoute superAdmin>
            <ParkList />
          </PrivateRoute>
        }
      />
      <Route
        path={routes.ADMIN_PARK_NEW}
        element={
          <PrivateRoute superAdmin>
            <ParkEdit />
          </PrivateRoute>
        }
      />
      <Route
        path={routes.ADMIN_CATEGORY_LIST}
        element={
          <PrivateRoute superAdmin>
            <CategoryList />
          </PrivateRoute>
        }
      />
      <Route
        path={routes.ADMIN_CATEGORY_SHOW}
        element={
          <PrivateRoute superAdmin>
            <CategoryShow />
          </PrivateRoute>
        }
      />
      <Route
        path={routes.ADMIN_CATEGORY_NEW}
        element={
          <PrivateRoute superAdmin>
            <CategoryEdit />
          </PrivateRoute>
        }
      />
      <Route
        path={routes.ADMIN_CATEGORY_EDIT}
        element={
          <PrivateRoute superAdmin>
            <CategoryEdit />
          </PrivateRoute>
        }
      />
    </RoutesRouter>
  );
};
