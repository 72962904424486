import {
  Avatar,
  LinearProgress,
  ListItem,
  ListItemAvatar,
  ListItemText,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from "@mui/material";
import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ANIMAL_SHOW } from "../../config/routes";
import { EnhancedTableHead, getComparator, HeadCell, Order, stableSort } from "../../core/utils/TableSort";
import { IAnimal } from "../models/animal";

interface Data {
  id: string;
  name: string;
  category: {
    slug: string;
    icon: string;
    type: string;
  };
  thumbnail: string;
  donations: {
    donations: number;
    goal: number;
    currency: string;
  };
  progress: number;
}

function createData(
  id: string,
  name: string,
  category: {
    slug: string;
    icon: string;
    type: string;
  },
  thumbnail: string,
  donations: {
    donations: number;
    goal: number;
    currency: string;
  },
  progress: number
): Data {
  return {
    id,
    name,
    category,
    thumbnail,
    donations,
    progress,
  };
}

const headCells: HeadCell<Data>[] = [
  {
    id: "name",
    numeric: false,
    label: "Animal",
  },
  {
    id: "category",
    numeric: false,
    label: "Catégorie",
  },
  {
    id: "donations",
    numeric: false,
    label: "Donations (EUR)",
  },
  {
    id: "progress",
    numeric: true,
    label: "Progress",
  },
];

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
}));

export default function ComponentAnimalList(props: { animals: IAnimal[]; toolbar?: JSX.Element }) {
  const navigate = useNavigate();

  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof Data>("name");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [animals, setAnimals] = useState<Data[]>([]);

  useEffect(() => {
    setAnimals(props.animals.map((a: any) => createData(a.id, a.name, a.category, a.thumbnail, a.donations, a.progress)));
  }, [props.animals]);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    navigate(ANIMAL_SHOW.replace(":animalId", name));
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - animals.length) : 0;

  return (
    <Box sx={{ width: "100%" }}>
      {props.toolbar}
      <TableContainer>
        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={"medium"}>
          <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={animals.length} headCells={headCells} />
          <TableBody>
            {stableSort(animals, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row: Data, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow hover onClick={(event) => handleClick(event, row.id)} role="checkbox" tabIndex={-1} key={row.id}>
                    <TableCell component="th" id={labelId} scope="row" padding="none">
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar src={row.thumbnail} />
                        </ListItemAvatar>
                        <ListItemText primary={row.name} />
                      </ListItem>
                    </TableCell>
                    <TableCell sx={{ width: "150px" }}>{row.category.slug}</TableCell>
                    <TableCell sx={{ width: "200px" }}>{row.donations ? `${row.donations.donations} / ${row.donations.goal}` : ""}</TableCell>
                    <TableCell sx={{ width: "150px" }} align="right">
                      {row.donations && (
                        <BorderLinearProgress
                          color={
                            row.donations.donations >= row.donations.goal
                              ? "success"
                              : row.donations.donations >= row.donations.goal / 2
                              ? "warning"
                              : "error"
                          }
                          variant="determinate"
                          value={
                            row.donations.donations >= row.donations.goal ? 100 : ((row.donations?.donations ?? 0) / (row.donations?.goal ?? 0)) * 100
                          }
                        />
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 53 * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 15, 30]}
        component="div"
        count={animals.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}
