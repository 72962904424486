import AddIcon from "@mui/icons-material/Add";
import { Avatar, ListItem, ListItemAvatar, ListItemText, Tab, Tabs } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import { collection, getDocs } from "firebase/firestore";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../../../config/firebase";
import { ADMIN_CATEGORY_NEW, ADMIN_CATEGORY_SHOW } from "../../../config/routes";
import { Type } from "../../../core/models/category";
import { EnhancedTableToolbar } from "../../../core/utils/TableSort";

interface Data {
  id: string;
  icon: string;
  main: boolean;
  slug: string;
  thumbnail: string;
  animalsCounter?: number;
  parksCounter?: number;
  parents?: string[];
  type: Type;
}

function createData(
  id: string,
  icon: string,
  main: boolean,
  slug: string,
  thumbnail: string,
  type: Type,
  animalsCounter?: number,
  parksCounter?: number,
  parents?: string[]
): Data {
  return {
    id,
    icon,
    main,
    slug,
    thumbnail,
    type,
    animalsCounter,
    parksCounter,
    parents,
  };
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "slug",
    numeric: false,
    label: "Nom",
  },
  {
    id: "main",
    numeric: false,
    label: "Main",
  },
  {
    id: "type",
    numeric: false,
    label: "Type",
  },
  {
    id: "animalsCounter",
    numeric: true,
    label: "Animaux / Parcs",
  },
];

interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, rowCount, onRequestSort } = props;
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} padding={"normal"} sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function CategoryList() {
  const navigate = useNavigate();
  const [tab, setTab] = React.useState(0);
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("main");
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(30);
  const [categoriesAnimal, setCategoriesAnimal] = React.useState<any>([]);
  const [categoriesPark, setCategoriesPark] = React.useState<any>([]);

  React.useEffect(() => {
    getDocs(collection(db, "categories")).then((docsSnap) => {
      if (!docsSnap.empty) {
        const animals: Data[] = [];
        const parks: Data[] = [];
        docsSnap.docs.forEach((d: any) => {
          const data = d.data();
          if (data.type === "animal") {
            animals.push(
              createData(
                d.id,
                data.icon ?? null,
                data.main ?? false,
                data.slug,
                data.thumbnail ?? null,
                data.type ?? "",
                data.animalsCounter ?? 0,
                data.parksCounter ?? 0,
                data.parents ?? []
              )
            );
          } else {
            parks.push(
              createData(
                d.id,
                data.icon ?? null,
                data.main ?? false,
                data.slug,
                data.thumbnail ?? null,
                data.type ?? "",
                data.animalsCounter ?? 0,
                data.parksCounter ?? 0,
                data.parents ?? []
              )
            );
          }
        });
        setCategoriesAnimal(animals);
        setCategoriesPark(parks);
      }
    });
  }, []);

  const handleChangeTab = (event: any, newValue: number) => {
    setTab(newValue);
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    navigate(ADMIN_CATEGORY_SHOW.replace(":categoryId", id));
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - categoriesAnimal.length) : 0;

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar
          title="Toutes les catégories de la plateforme"
          actions={[
            <Button
              key="add"
              startIcon={<AddIcon />}
              variant="contained"
              onClick={() => navigate(ADMIN_CATEGORY_NEW)}
              sx={{ width: 250 }}
            >
              Ajouter une catégorie
            </Button>,
          ]}
        />
        <Tabs value={tab} onChange={handleChangeTab}>
          <Tab label="Animaux" />
          <Tab label="Parcs" />
        </Tabs>
        <Box>
          <div role="tabpanel" hidden={tab !== 0} id={`simple-tabpanel-${0}`} aria-labelledby={`simple-tab-${0}`}>
            <TableContainer>
              <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={dense ? "small" : "medium"}>
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={categoriesAnimal.length}
                />
                <TableBody>
                  {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                    rows.slice().sort(getComparator(order, orderBy)) */}
                  {stableSort(categoriesAnimal, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row: any, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow hover onClick={(event) => handleClick(event, row.id)} tabIndex={-1} key={row.id}>
                          <TableCell component="th" id={labelId} scope="row">
                            <ListItem>
                              <ListItemAvatar>
                                <Avatar sx={{ width: 50, height: 50 }} src={row.icon ?? row.thumbnail}></Avatar>
                              </ListItemAvatar>
                              <ListItemText primary={row.slug} />
                            </ListItem>
                          </TableCell>
                          <TableCell sx={{ width: "150px" }}>
                            {row.main === true ? "Oui" : row.main === false ? "Non" : ""}
                          </TableCell>
                          <TableCell sx={{ width: "150px" }}>{row.type}</TableCell>
                          <TableCell sx={{ width: "170px", textAlign: "center" }}>
                            {row.animalsCounter ?? row.parksCounter}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 15, 30]}
              component="div"
              count={categoriesAnimal.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
          <div role="tabpanel" hidden={tab !== 1} id={`simple-tabpanel-${1}`} aria-labelledby={`simple-tab-${1}`}>
            <TableContainer>
              <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={dense ? "small" : "medium"}>
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={categoriesPark.length}
                />
                <TableBody>
                  {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                    rows.slice().sort(getComparator(order, orderBy)) */}
                  {stableSort(categoriesPark, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row: any, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow hover onClick={(event) => handleClick(event, row.id)} tabIndex={-1} key={row.id}>
                          <TableCell component="th" id={labelId} scope="row">
                            <ListItem>
                              <ListItemAvatar>
                                <Avatar sx={{ width: 50, height: 50 }} src={row.icon ?? row.thumbnail}></Avatar>
                              </ListItemAvatar>
                              <ListItemText primary={row.slug} />
                            </ListItem>
                          </TableCell>
                          <TableCell sx={{ width: "150px" }}>
                            {row.main === true ? "Oui" : row.main === false ? "Non" : ""}
                          </TableCell>
                          <TableCell sx={{ width: "150px" }}>{row.type}</TableCell>
                          <TableCell sx={{ width: "170px", textAlign: "center" }}>
                            {row.animalsCounter ?? row.parksCounter}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 15, 30]}
              component="div"
              count={categoriesPark.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </Box>
      </Paper>
    </Box>
  );
}
