import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Button, Chip, IconButton, LinearProgress, Menu, MenuItem, styled, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow } from '@mui/material';
import Box from '@mui/material/Box';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../../auth/provideAuthHook';
import { IDonation } from '../../../core/models/donation';
import * as AnimalService from '../../../core/services/animal-service';
import { EnhancedTableHead, getComparator, HeadCell, Order, stableSort } from '../../../core/utils/TableSort';

interface Data {
  id: string;
  amount: number;
  currency: string;
  createdAt: Date;
  userId: string;
  campaignId: string;
  paymentId: string;
  isPrivate: boolean;
}

function createData(
  id: string,
  amount: number,
  currency: string,
  createdAt: Date,
  userId: string,
  campaignId: string,
  paymentId: string,
  isPrivate: boolean
): Data {
  return {
    id,
    amount,
    currency,
    createdAt,
    userId,
    campaignId,
    paymentId,
    isPrivate
  };
}

const headCells: HeadCell<Data>[] = [
  {
    id: 'amount',
    numeric: true,
    label: 'Montant',
  },
  {
    id: 'userId',
    numeric: false,
    label: 'User Id',
  },
  {
    id: 'campaignId',
    numeric: false,
    label: 'Campaign Id',
  },
  {
    id: 'isPrivate',
    numeric: false,
    label: 'Public',
  },
  {
    id: 'createdAt',
    numeric: false,
    label: 'Payé le',
  },
];

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
}));

export default function DonationList() {
  const authHook = useAuth();
  const navigate = useNavigate();
  const { animalId } = useParams();
  
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof Data>('id');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [donations, setDonations] = useState<Data[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - donations.length) : 0;

  useEffect(() => {
    if (animalId) {
      AnimalService.allDonations(animalId).then((ds: IDonation[]) => setDonations(ds.map((d) => createData(
        d.id,
        d.amount,
        d.currency,
        d.createdAt,
        d.userId,
        d.campaignId ?? '',
        d.paymentId,
        d.private,
      ))));
    }
  }, [animalId]);

  return (
    <Box sx={{ width: '100%' }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={'medium'}
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={donations.length}
              headCells={headCells}
              actions
            />
            <TableBody>
              {stableSort(donations, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: Data, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => {}}
                      tabIndex={-1}
                      key={row.id}
                    >
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        sx={{ width: '100px'}} align="right"
                      >
                        {row.amount} {row.currency == 'eur' ? '€' : '$'}
                      </TableCell>
                      <TableCell sx={{ width: '200px'}}>
                        <Button
                          variant="text"
                        >
                          {row.userId}
                        </Button>
                      </TableCell>
                      <TableCell sx={{ width: '200px'}}>
                        <Button
                          variant="text"
                        >
                          {row.campaignId}
                        </Button>
                      </TableCell>
                      <TableCell sx={{ width: '100px'}}><Chip label={row.isPrivate ? 'Non' : 'Oui'} color={row.isPrivate ? 'error' : 'success'} /></TableCell>
                      <TableCell sx={{ width: '120px'}}>{format(row.createdAt, 'dd/MM/yyyy HH:mm')}</TableCell>
                      <TableCell sx={{ width: '50px'}} align="right">
                        <IconButton
                          id={`${index}-positioned-menu`}
                          aria-controls={open ? `${index}-positioned-menu` : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? 'true' : undefined}
                          onClick={handleClick}
                          size="large"
                        >
                          <MoreVertIcon fontSize="inherit" />
                        </IconButton>
                        <Menu
                          id={`${index}-positioned-menu`}
                          aria-labelledby={`${index}-positioned-menu`}
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          sx={{ }}
                        >
                          {authHook.superAdmin && <MenuItem onClick={() => {
                             window.open(`https://dashboard.stripe.com/test/payments/${row.paymentId}`, '_blank');
                          }}>Voir sur Stripe</MenuItem>}
                        </Menu>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 15, 30]}
          component="div"
          count={donations.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
    </Box>
  );
}
