import { Box, Button, ButtonGroup, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useAuth } from "../../../auth/provideAuthHook";
import MenuButton from "../../../core/components/MenuButton";
import { IReward, Recurrence, Reward } from "../../../core/models/reward";
import { addReward, allRewards, editReward } from "../../../core/services/animal-service";
import { RewardEdit } from "./RewardEdit";

export default function RewardManagement(props: any) {
  const auth = useAuth();

  const [rewards, setRewards] = useState<Reward[]>([]);
  const [rewardSelectedIndex, setRewardSelectedIndex] = useState<number>(-1);
  const [rewardsFiltered, setRewardsFiltered] = useState<Reward[]>([]);
  const [openEditReward, setOpenEditReward] = useState(false);
  const [group, setGroup] = useState<Recurrence>(Recurrence.none);
  const [errors, setErrors] = useState<{ name: string; message: string }[]>([]);

  const handleClickOpenEditReward = (index: number) => {
    setRewardSelectedIndex(index);
    setOpenEditReward(true);
  };

  const handleCloseEditReward = (reward?: IReward) => {
    setErrors([]);
    if (reward) {
      if (Reward.errors(reward).length === 0) {
        if (reward.id !== "" && rewardSelectedIndex >= 0) {
          editReward(props.animal.id, reward.id, {
            name: reward.name,
            description: reward.description,
            prices: reward.prices,
            gifts: reward.gifts,
          }).then(() => {
            setOpenEditReward(false);
            setRewardSelectedIndex(-1);
            rewards[rewardSelectedIndex] = {
              ...rewards[rewardSelectedIndex],
              ...reward,
            };
            setRewards(rewards);
          });
        } else {
          addReward(props.animal.id, {
            name: reward.name,
            description: reward.description,
            prices: reward.prices,
            gifts: reward.gifts,
          }).then((reward: Reward) => {
            setOpenEditReward(false);
            setRewardSelectedIndex(-1);
            rewards.push(reward);
            setRewards(rewards);
          });
        }
      } else {
        console.log("test");
        setErrors(Reward.errors(reward));
      }
    } else {
      setRewardSelectedIndex(-1);
      setOpenEditReward(false);
    }
  };

  const getRewards = useCallback(() => {
    if (props.animal.id) {
      allRewards(props.animal.id).then((r) => {
        setRewards(r);
        setRewardsFiltered([...r].filter((reward) => reward.prices.find((p) => p.recurrence === Recurrence.none)));
      });
    }
  }, [props.animal.id]);

  useEffect(() => {
    getRewards();
  }, [getRewards]);

  useEffect(() => {
    setRewardsFiltered([...rewards].filter((reward) => reward.prices.find((p) => p.recurrence === group)));
  }, [group, rewards]);

  return (
    <Box>
      {openEditReward && (
        <RewardEdit
          animal={props.animal}
          errors={errors}
          gifts={props.gifts}
          reward={rewardSelectedIndex >= 0 ? rewards[rewardSelectedIndex] : undefined}
          open={openEditReward}
          onClose={handleCloseEditReward}
        />
      )}
      <ButtonGroup variant="outlined" aria-label="outlined button group">
        <Button
          onClick={() => setGroup(Recurrence.none)}
          variant={group === Recurrence.none ? "contained" : "outlined"}
        >
          Une fois
        </Button>
        <Button
          onClick={() => setGroup(Recurrence.week)}
          variant={group === Recurrence.week ? "contained" : "outlined"}
        >
          Par semaine
        </Button>
        <Button
          onClick={() => setGroup(Recurrence.month)}
          variant={group === Recurrence.month ? "contained" : "outlined"}
        >
          Par mois
        </Button>
        <Button
          onClick={() => setGroup(Recurrence.year)}
          variant={group === Recurrence.year ? "contained" : "outlined"}
        >
          Par an
        </Button>
      </ButtonGroup>
      <Button onClick={() => handleClickOpenEditReward(-1)} variant="contained" sx={{ ml: 2 }} color="success">
        Ajouter un pack de contrepartie
      </Button>
      <List>
        {rewardsFiltered.map((reward, index) => {
          const price = reward.prices.find((p) => p.recurrence === group);
          return (
            <ListItem
              key={index}
              secondaryAction={
                <MenuButton
                  items={[
                    { name: "Modifier", onClick: () => handleClickOpenEditReward(index) },
                    { name: "Supprimer", onClick: () => {} },
                  ]}
                />
              }
            >
              <ListItemIcon>
                {!price?.max && "A partir de "}
                {price?.min}
                {price?.max ? ` à ${price.max}€` : "€"}
              </ListItemIcon>
              <ListItemText
                primary={`${reward.name.fr}`}
                secondary={reward.gifts.map((gift, index) => `${index > 0 ? " • " : ""}${gift.name.fr}`)}
              />
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
}
