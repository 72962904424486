import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import Avatar from "@mui/material/Avatar/Avatar";
import Box from "@mui/material/Box/Box";
import ListItemText from "@mui/material/ListItemText/ListItemText";
import { Fragment, useEffect, useState } from "react";
import FileUploaderMultiple, { IFile } from "../../../core/components/FileUploader";
import { ICategory, Type } from "../../../core/models/category";
import * as categoryService from "../../../core/services/category-service";

interface FormComponentProps {
  errors: { name: string; message: string }[];
  file?: IFile;
  category: ICategory;
  imagesCategories: IFile[];
  icon?: IFile;
  handleChangeFieldText: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeThumbnail: (files: IFile[]) => void;
  handleChangeParents: (categories: ICategory[]) => void;
  handleChangeChildren: (categories: ICategory[]) => void;
  handleChangeMain: (value: boolean) => void;
  handleChangeType: (type: Type) => void;
  handleChangeIcon: (icon: IFile) => void;
}

export function FormComponent(props: FormComponentProps) {
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [indexIcon, setIndexIcon] = useState<number>(-1);

  useEffect(() => {
    categoryService.all().then((categories: ICategory[]) => {
      setCategories(categories);
    });
  }, []);

  useEffect(() => {
    if (props.icon) {
      setIndexIcon(props.imagesCategories.findIndex((f) => f.image?.src === props.icon?.image?.src));
    }
  }, [props.icon, props.imagesCategories]);

  return (
    <Fragment>
      <FormControl error={!!props.errors.find((e) => e.name === "thumbnail")} fullWidth>
        <FileUploaderMultiple
          files={props.file ? [props.file] : []}
          multiple={false}
          label="Thumbnail"
          onChange={props.handleChangeThumbnail}
        />
        <FormHelperText>{props.errors.find((e) => e.name === "thumbnail")?.message}</FormHelperText>
      </FormControl>
      <FormControl sx={{ mt: 2 }} fullWidth>
        <TextField
          fullWidth
          variant="outlined"
          label="Slug"
          name="slug"
          required
          disabled
          value={props.category.slug}
          onChange={props.handleChangeFieldText}
          error={!!props.errors.find((e) => e.name === "slug")}
          helperText={props.errors.find((e) => e.name === "slug")?.message}
        />
      </FormControl>

      <FormControl error={!!props.errors.find((e) => e.name === "parents")} sx={{ mt: 2 }} fullWidth>
        <InputLabel id="select-categories-parent">Catégories parent</InputLabel>
        <Select
          labelId="select-categories-parent"
          value={props.category.parents || []}
          label="Catégories parent"
          multiple
          required
          onChange={(event) => {
            props.handleChangeParents(categories.filter((c: ICategory) => event.target.value.includes(c.id)));
          }}
        >
          {categories.map((c: any) => (
            <MenuItem key={c.id} value={c.id}>
              {c.slug}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{props.errors.find((e) => e.name === "parents")?.message}</FormHelperText>
      </FormControl>

      <FormControl error={!!props.errors.find((e) => e.name === "children")} sx={{ mt: 2 }} fullWidth>
        <InputLabel id="select-categories-parent">Catégories enfant</InputLabel>
        <Select
          labelId="select-categories-children"
          value={props.category.children || []}
          label="Catégories enfant"
          multiple
          required
          onChange={(event) => {
            props.handleChangeChildren(categories.filter((c: ICategory) => event.target.value.includes(c.id)));
          }}
        >
          {categories.map((c: any) => (
            <MenuItem key={c.id} value={c.id}>
              {c.slug}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{props.errors.find((e) => e.name === "children")?.message}</FormHelperText>
      </FormControl>

      <FormControl sx={{ mt: 2 }} fullWidth>
        <Select
          displayEmpty
          onChange={(event) => {
            if (
              event.target.value &&
              Number.isInteger(+event.target.value) &&
              props.imagesCategories[+event.target.value]
            ) {
              props.handleChangeIcon(props.imagesCategories[+event.target.value]);
            }
          }}
          value={indexIcon >= 0 ? indexIcon : ""}
          renderValue={(valueIndex: any) => {
            return (
              <Box sx={{ display: "flex", gap: 1 }}>
                {valueIndex === "" && <Avatar src={props.category?.icon} />}
                {valueIndex !== "" && <Avatar src={props.imagesCategories[valueIndex].image?.src} />}
                {valueIndex !== "" && <ListItemText primary={props.imagesCategories[valueIndex].image?.alt} />}
              </Box>
            );
          }}
        >
          {props.imagesCategories.map((c, index) => (
            <MenuItem key={index} value={index}>
              <Avatar src={c.image?.src} />
              <ListItemText primary={c.image?.alt} />
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{props.imagesCategories.length} images</FormHelperText>
      </FormControl>

      <FormControl error={!!props.errors.find((e) => e.name === "main")} sx={{ mt: 2 }} fullWidth>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  props.handleChangeMain(event.target.checked);
                }}
                checked={props.category.main}
              />
            }
            label="Principal"
          />
        </FormGroup>
      </FormControl>

      <FormControl error={!!props.errors.find((e) => e.name === "type")} sx={{ mt: 2 }} fullWidth>
        <InputLabel id="select-type">Type</InputLabel>
        <Select
          labelId="select-type"
          value={props.category.type}
          label="Type"
          required
          onChange={(event) => {
            props.handleChangeType(event.target.value.toLowerCase() as Type);
          }}
        >
          {Object.values(Type).map((t, i) => (
            <MenuItem key={i} value={t}>
              {t}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{props.errors.find((e) => e.name === "parents")?.message}</FormHelperText>
      </FormControl>
    </Fragment>
  );
}
