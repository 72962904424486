import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Card, CardActions, CardContent, Grid, IconButton } from "@mui/material";
import { collection, doc } from "firebase/firestore";
import { deleteObject, getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { db, storage } from "../../config/firebase";
import { IFile } from "../../core/components/FileUploader";
import { usePark } from "../../core/hooks/park/provideParkHook";
import { ICategory } from "../../core/models/category";
import { Image } from "../../core/models/image";
import { ILanguages } from "../../core/models/languages";
import { IPark, Park } from "../../core/models/park";
import { add, get, update } from "../../core/services/park-service";
import { FormComponent } from "./FormComponent";

interface EditProps {
  id?: string;
}

export function ParkEdit(props: EditProps) {
  const [park, setPark] = useState<IPark>(new Park());
  const [files, setFiles] = useState<IFile[]>([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<{ name: string; message: string }[]>([]);

  const navigate = useNavigate();
  const parkHook = usePark();

  const handleChangeCategory = (category: ICategory) => {
    setPark({
      ...park,
      category: {
        id: category.id,
        slug: category.slug,
        icon: category.icon ?? "",
      },
    });
  };

  const handleChangeFieldText = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPark({
      ...park,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeFieldLatitude = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPark({
      ...park,
      localization: {
        geohash: park.localization.geohash,
        geopoint: {
          latitude: event.target.value,
          longitude: park.localization.geopoint.longitude,
        },
      },
    });
  };

  const handleChangeFieldLongitude = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPark({
      ...park,
      localization: {
        geohash: park.localization.geohash,
        geopoint: {
          latitude: park.localization.geopoint.latitude,
          longitude: event.target.value,
        },
      },
    });
  };

  const handleChangeShortDescription = (value: ILanguages) => {
    setPark({
      ...park,
      shortDescription: value,
    });
  };

  const handleChangeFullDescription = (value: ILanguages) => {
    setPark({
      ...park,
      fullDescription: value,
    });
  };

  const handleSubmit = async () => {
    if (Park.errors(park).length === 0 && files.length > 0) {
      setLoading(true);
      try {
        const filesClone = [...files];
        for (let index = 0; index < filesClone.length; index++) {
          const file = filesClone[index];
          if (file.delete) {
            if (file.image && file.image.ref) {
              await deleteObject(ref(storage, file.image.ref));
            }
            filesClone.splice(index, 1);
          } else if (file.file) {
            const regName = file.file.name.match(/(^[\w,\s-]+)(\.[A-Za-z]{3,4})$/);
            const name = index === 0 && regName?.length === 3 ? "thumbnail" + regName[2] : file.file.name;
            const fileUploaded = await uploadBytes(ref(storage, `parks/${park.id}/${name}`), file.file);
            const url = await getDownloadURL(fileUploaded.ref);
            filesClone[index].image = { src: url, alt: file.alt, ref: fileUploaded.ref.toString() } as Image;
          } else if (file.image) {
            filesClone[index].image = { ...file.image, alt: file.alt !== "" ? file.alt : file.image.alt } as Image;
          }
        }
        const images = filesClone.map((file: IFile) => file.image as Image);

        if (props.id) {
          update(props.id, {
            ...park,
            images: images.length > 1 ? images.slice(1) : [],
            thumbnail: images[0].src,
          })
            .then(() => {
              setLoading(false);
              navigate("/park");
            })
            .catch((error: any) => {
              console.log(error);
              setLoading(false);
            });
        } else {
          add({
            ...park,
            images: images.length > 1 ? images.slice(1) : [],
            thumbnail: images[0].src,
          })
            .then(async (p) => {
              setLoading(false);
              await parkHook.refresh();
              parkHook.selectPark(p);
              navigate("/park");
            })
            .catch((error: any) => {
              console.log(error);
              setLoading(false);
            });
        }
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
    } else {
      setErrors(Park.errors(park));
    }
  };

  const handleChangeImage = async (filesAdded: IFile[]) => {
    setFiles(filesAdded);
  };

  useEffect(() => {
    if (props.id) {
      get(props.id).then((p: IPark) => {
        let filesCloned = [{ alt: "Thumbnail", image: { alt: "Thumbnail", src: p.thumbnail } as Image } as IFile];
        setPark(p);
        if (p.images) {
          filesCloned = [...filesCloned, ...p.images.map((image: Image) => ({ alt: "", image: image } as IFile))];
        }
        setFiles(filesCloned);
      });
    } else {
      setPark(new Park({ id: doc(collection(db, "parks")).id } as any));
    }
  }, [props.id]);

  return (
    <Box component="main" sx={{ flex: 1, pt: 2, pb: 6, px: 4, bgcolor: "#eaeff1" }}>
      <IconButton aria-label="back" sx={{ mb: 2 }} onClick={() => navigate(-1)}>
        <ArrowBackIcon />
      </IconButton>
      <Grid container spacing={2}>
        <Grid item lg={4} />

        <Grid item lg={4}>
          <Box
            noValidate
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Card>
              <CardContent>
                <FormComponent
                  errors={errors}
                  files={files}
                  park={park}
                  handleChangeFieldText={handleChangeFieldText}
                  handleChangeImage={handleChangeImage}
                  handleChangeFieldLatitude={handleChangeFieldLatitude}
                  handleChangeFieldLongitude={handleChangeFieldLongitude}
                  handleChangeCategory={handleChangeCategory}
                  handleChangeShortDescription={handleChangeShortDescription}
                  handleChangeFullDescription={handleChangeFullDescription}
                />
              </CardContent>
              <CardActions sx={{ justifyContent: "center" }}>
                <LoadingButton color="success" onClick={handleSubmit} loading={loading} variant="contained">
                  {props.id ? "Editer" : "Ajouter"}
                </LoadingButton>
              </CardActions>
            </Card>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
