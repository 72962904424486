import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  AlertProps,
  AlertTitle,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  OutlinedInput,
  Stack,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import { eachDayOfInterval, format, formatDistanceToNowStrict } from "date-fns";
import frLocale from "date-fns/locale/fr";
import { useCallback, useEffect, useState } from "react";
import { useAuth } from "../../../auth/provideAuthHook";
import MenuButton from "../../../core/components/MenuButton";
import { Campaign } from "../../../core/models/campaign";
import { addCampaign, allCampaigns, editCampaign, updateGoal } from "../../../core/services/animal-service";
import { DonationNewCampaign } from "./DonationNewCampaign";

const CurrentCampaign = styled(Alert)<AlertProps>(({ theme }) => ({
  "& .MuiAlert-action, & .MuiAlert-icon": {
    alignItems: "center",
  },
}));

export default function DonationCompaign(props: any) {
  const auth = useAuth();

  const [amountUpdated, setAmountUpdated] = useState<any>();

  const [openNewCampaign, setOpenNewCampaign] = useState(false);
  const [futurCampaigns, setFuturCampaigns] = useState<Campaign[]>([]);
  const [currentCampaign, setCurrentCampaign] = useState<Campaign>();
  const [excludeDates, setExcludeDates] = useState<Date[]>([]);
  const [campaignSelected, setCampaignSelected] = useState<Campaign>();

  const [openConfirmCancelCampaign, setOpenConfirmCancelCampaign] = useState(false);
  const [loadingConfirmCancelCampaign, setLoadingConfirmCancelCampaign] = useState(false);

  const handleClickOpenNewCampaign = () => {
    setCampaignSelected(undefined);
    setOpenNewCampaign(true);
  };

  const handleClickOpenEditCampaign = (campaign: Campaign) => {
    setCampaignSelected(campaign);
    setOpenNewCampaign(true);
  };

  const handleCloseNewCampaign = (campaign?: Campaign) => {
    if (campaign) {
      if (campaign.id && campaign.id === campaignSelected?.id) {
        editCampaign(props.animal.id, campaign.id, campaign).then(() => {
          setOpenNewCampaign(false);
          setCampaignSelected(undefined);
          getCampaigns();
        });
      } else {
        campaign.createdBy = auth.user?.id ?? "";
        addCampaign(props.animal.id, campaign).then((campaign: Campaign) => {
          setOpenNewCampaign(false);
          setCampaignSelected(undefined);
          getCampaigns();
        });
      }
    } else {
      setOpenNewCampaign(false);
      setCampaignSelected(undefined);
    }
  };

  const handleClickCancelCampaign = (campaign: Campaign) => {
    setCampaignSelected(campaign);
    setOpenConfirmCancelCampaign(true);
  };

  const handleCancelCampaign = () => {
    setLoadingConfirmCancelCampaign(true);
    if (campaignSelected != null) {
      const now = new Date();
      editCampaign(props.animal.id, campaignSelected.id, {
        endAt: now,
        canceledAt: now,
        canceledBy: auth.user?.id,
      }).then(() => {
        setOpenConfirmCancelCampaign(false);
        setLoadingConfirmCancelCampaign(false);
        setCampaignSelected(undefined);
        getCampaigns();
      });
    }
  };

  const handleCloseConfirmCancelCampaign = () => {
    setCampaignSelected(undefined);
  };

  const handleSubmitAmount = () => {
    updateGoal(props.animal.id, amountUpdated).then(() => {
      props.refresh();
      if (amountUpdated === "0" || amountUpdated === 0) {
        setAmountUpdated(undefined);
      }
    });
  };

  const getCampaigns = useCallback(() => {
    if (props.animal.id) {
      allCampaigns(props.animal.id).then((campaigns) => {
        const now = new Date();
        const currentCampaignIndex = campaigns.findIndex((c) => c.startedAt <= now && c.endAt > now);
        setCurrentCampaign(campaigns[currentCampaignIndex]);
        const cloneCampaigns = [...campaigns];
        cloneCampaigns.splice(currentCampaignIndex, 1);
        setFuturCampaigns(cloneCampaigns);
        const excludes = [...campaigns].reduce((arr: any, item) => {
          arr.push(...eachDayOfInterval({ start: item.startedAt, end: item.endAt }));
          return arr;
        }, []);
        setExcludeDates(excludes);
      });
    }
  }, [props.animal.id]);

  useEffect(() => {
    getCampaigns();
  }, [getCampaigns]);

  useEffect(() => {
    setAmountUpdated(props.animal.donations?.goal);
  }, [props.animal.donations?.goal]);

  return (
    <Box>
      {openNewCampaign && (
        <DonationNewCampaign
          open={openNewCampaign}
          onClose={handleCloseNewCampaign}
          disabledDates={excludeDates}
          campaign={campaignSelected}
        />
      )}
      {openConfirmCancelCampaign && (
        <Dialog open={openConfirmCancelCampaign} onClose={handleCloseConfirmCancelCampaign}>
          <DialogTitle>Êtes-vous VRAIMENT certains de vouloir annuler la campagne ?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Cette action irrémédiable va <b>ANNULER</b> la campagne en cours.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <LoadingButton loading={loadingConfirmCancelCampaign} onClick={handleCloseConfirmCancelCampaign}>
              Annuler
            </LoadingButton>
            <LoadingButton
              color="error"
              disabled={loadingConfirmCancelCampaign}
              onClick={handleCancelCampaign}
              loading={loadingConfirmCancelCampaign}
              variant="contained"
            >
              Confirmer
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <Typography>Dépenses moyenne mensuel</Typography>
          <Typography sx={{ fontSize: 12, color: "rgba(0, 0, 0, 0.4)" }}>Objectif à atteindre si aucune</Typography>
          <Typography sx={{ fontSize: 12, color: "rgba(0, 0, 0, 0.4)" }}>
            campagne en cours (Reset tous les mois)
          </Typography>
        </Grid>
        <Grid item>
          <FormControl fullWidth sx={{ m: 1 }}>
            <InputLabel htmlFor="outlined-adornment-amount">Montant</InputLabel>
            <OutlinedInput
              onChange={(event: any) => {
                setAmountUpdated(event.currentTarget.value);
              }}
              type="number"
              value={amountUpdated}
              endAdornment={
                <InputAdornment position="end">
                  {amountUpdated && +amountUpdated !== props.animal.donations?.goal ? (
                    <IconButton
                      onClick={handleSubmitAmount}
                      onMouseDown={(event: any) => event.preventDefault()}
                      edge="end"
                    >
                      <CheckIcon />
                    </IconButton>
                  ) : (
                    "€"
                  )}
                </InputAdornment>
              }
              id="outlined-basic"
              label="amount"
            />
          </FormControl>
        </Grid>
      </Grid>
      <Divider sx={{ marginBottom: 2, marginTop: 2 }} />
      <Grid container justifyContent="end">
        <Grid item>
          <Button variant="outlined" startIcon={<AddIcon />} onClick={handleClickOpenNewCampaign}>
            Nouvelle campagne
          </Button>
        </Grid>
      </Grid>
      <Typography variant="h6" gutterBottom>
        Campagne en cours
      </Typography>
      {!currentCampaign && (
        <Typography sx={{ color: "rgba(0,0,0,0.4)" }} gutterBottom>
          Aucune campagne actuellement
        </Typography>
      )}
      {currentCampaign && (
        <CurrentCampaign
          sx={{ marginBottom: 2 }}
          severity="success"
          variant="filled"
          icon={
            <>
              <Typography variant="h6" component="span">
                <strong>
                  {(
                    ((currentCampaign.donations?.donations ?? 0) / (currentCampaign.donations?.goal ?? 0)) *
                    100
                  ).toFixed()}
                </strong>
              </Typography>
              <Typography>%</Typography>
            </>
          }
          action={
            <Stack direction="row">
              <Tooltip title="Editer la campagne">
                <IconButton
                  aria-label="edit"
                  sx={{ color: "white" }}
                  onClick={() => handleClickOpenEditCampaign(currentCampaign)}
                >
                  <EditIcon color="inherit" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Arrêter la campagne">
                <IconButton
                  aria-label="delete"
                  sx={{ color: "white" }}
                  onClick={() => handleClickCancelCampaign(currentCampaign)}
                >
                  <CloseIcon color="inherit" />
                </IconButton>
              </Tooltip>
            </Stack>
          }
        >
          <AlertTitle sx={{ marginBottom: 0 }}>
            {currentCampaign.name.fr} • {currentCampaign.donations?.donations ?? 0} /{" "}
            {currentCampaign.donations?.goal ?? 0}€
          </AlertTitle>
          Fini dans <strong>{formatDistanceToNowStrict(currentCampaign.endAt, { locale: frLocale })}</strong> •{" "}
          {format(currentCampaign.startedAt, "dd/MM/yyyy HH:mm")} au {format(currentCampaign.endAt, "dd/MM/yyyy HH:mm")}
        </CurrentCampaign>
      )}
      <Typography variant="h6" sx={{ mt: 4 }}>
        Campagnes prévues
      </Typography>
      {futurCampaigns.length === 0 && (
        <Typography sx={{ color: "rgba(0,0,0,0.4)" }} gutterBottom>
          Aucune campagne prévue
        </Typography>
      )}
      <List>
        {futurCampaigns.map((campaign, index) => (
          <ListItem
            key={index}
            secondaryAction={
              <MenuButton
                items={[
                  { name: "Modifier la campagne", onClick: () => handleClickOpenEditCampaign(campaign) },
                  { name: "Annuler la campagne", onClick: () => {} },
                ]}
              />
            }
          >
            <ListItemIcon>
              <AccessTimeIcon sx={{ fontSize: "35px!important" }} />
            </ListItemIcon>
            <ListItemText
              primary={`${campaign.name.fr} • ${campaign.donations?.goal ?? 0}€`}
              secondary={`Dans ${formatDistanceToNowStrict(campaign.startedAt, { locale: frLocale })} • ${format(
                campaign.startedAt,
                "dd/MM/yyyy HH:mm"
              )} au ${format(campaign.endAt, "dd/MM/yyyy HH:mm")}`}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
}
