import { Box, Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import { IAnimal } from "../../../core/models/animal";
import { Gift } from "../../../core/models/reward";
import { allGifts } from "../../../core/services/animal-service";
import GiftManagement from "./GiftManagement";
import RewardManagement from "./RewardManagement";

interface RewardPageProps {
  animal: IAnimal;
}

export function RewardPage(props: RewardPageProps) {
  const [tab, setTab] = useState(0);
  const [gifts, setGifts] = useState<Gift[]>([]);

  const handleChangeTab = (event: any, newValue: number) => {
    setTab(newValue);
  };

  useEffect(() => {
    if (props.animal.id) {
      allGifts(props.animal.id).then((g) => {
        setGifts(g);
      });
    }
  }, [props.animal.id]);

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}>
        <Tabs value={tab} onChange={handleChangeTab}>
          <Tab label="Packs de contrepartie" />
          <Tab label="Contreparties" />
        </Tabs>
      </Box>
      <Box>
        <div role="tabpanel" hidden={tab !== 0} id={`reward-tabpanel-${0}`} aria-labelledby={`reward-tab-${0}`}>
          <RewardManagement animal={props.animal} gifts={gifts} />
        </div>
        <div role="tabpanel" hidden={tab !== 1} id={`reward-tabpanel-${1}`} aria-labelledby={`reward-tab-${1}`}>
          <GiftManagement animal={props.animal} gifts={gifts} />
        </div>
      </Box>
    </>
  );
}
