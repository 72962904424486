import AddIcon from '@mui/icons-material/Add';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import { Button, ButtonProps, Menu, MenuItem, styled } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../auth/provideAuthHook';
import { usePark } from '../../core/hooks/park/provideParkHook';

const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: '#fff',
}));

interface HeaderProps {
  onDrawerToggle: () => void;
}

export default function Header(props: HeaderProps) {
  const { onDrawerToggle } = props;

  const authHook = useAuth();
  const parkHook = usePark();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (index: number | null) => {
    if (index !== null) {
      parkHook.selectPark(parkHook.userParks[index].park);
    } else {
      parkHook.selectPark(null);
    }
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <AppBar color="primary" position="sticky" elevation={0}>
        <Toolbar>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={onDrawerToggle}
                edge="start"
                sx={{ display: { sm: 'none', xs: 'block' } }}
              >
                <MenuIcon />
              </IconButton>
              {parkHook.userParks.length === 1 && `${parkHook.park?.name ?? ''}`}
              {parkHook.userParks.length > 1 && <>
                <ColorButton
                  color="secondary"
                  id="basic-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                  endIcon={open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                >
                  {parkHook.park ? `${parkHook.park.name}` : 'Sélectionner un parc'}
                </ColorButton>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={() => setAnchorEl(null)}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  {authHook.superAdmin && <MenuItem onClick={() => handleClose(null)}>-- Sélectionner un parc --</MenuItem>}
                  {parkHook.userParks.map((up: any, index: number) => <MenuItem key={index} onClick={() => handleClose(index)}>{up.park.name}</MenuItem>)}
                </Menu>
              </>}
              {authHook.superAdmin && <IconButton
                  color="inherit"
                  aria-label="add park"
                  edge="start"
                  onClick={() => navigate('/admin/parks/new')}
                  sx={{ ml: 1}}
                >
                  <AddIcon />
                </IconButton>}
            </Grid>
            <Grid item xs />
            <Grid item>
              {authHook.user ? `${authHook.user.firstName}` : 'Me connecter'}
            </Grid>
            {authHook.user && <Grid item>
              <IconButton color="inherit" sx={{ p: 0.5 }} onClick={() => authHook.signout()}>
                <LogoutIcon />
              </IconButton>
            </Grid>}
          </Grid>
        </Toolbar>
      </AppBar>
    </Fragment>
  );
}