import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Card, CardActions, CardContent, FormControl, FormHelperText, Grid, IconButton, InputLabel, MenuItem, Select, Tab, Tabs, TextField } from '@mui/material';
import { collection, doc, where } from 'firebase/firestore';
import { deleteObject, getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { db, storage } from '../../config/firebase';
import FileUploaderMultiple, { IFile } from '../../core/components/FileUploader';
import { usePark } from '../../core/hooks/park/provideParkHook';
import { Animal, IAnimal } from '../../core/models/animal';
import { ICategory } from '../../core/models/category';
import { Image } from '../../core/models/image';
import { add, get, update } from '../../core/services/animal-service';
import { all } from '../../core/services/category-service';

export function AnimalEdit() {
  const [animal, setAnimal] = useState<IAnimal>(new Animal());
  const [files, setFiles] = useState<IFile[]>([]);
  const [thumbnail, setThumbnail] = useState<IFile>();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<{ name: string, message: string }[]>([]);
  const [tab, setTab] = useState(0);
  const [categories, setCategories] = useState<ICategory[]>([]);

  const navigate = useNavigate();
  const { animalId } = useParams();
  const parkHook = usePark();

  const handleChangeTab = (event: any, newValue: number) => {
    setTab(newValue);
  };

  const handleChangeCategory = (category: ICategory) => {
    setAnimal({
      ...animal,
      category: {
        id: category.id,
        slug: category.slug,
        icon: category.icon ?? '',
      }
    });
  };

  const handleChangeFieldText = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAnimal({
      ...animal,
      [event.target.name]: event.target.value
    });
    event.stopPropagation();
  };

  const handleSubmit = async () => {
    if (Animal.errors(animal).length === 0 && thumbnail) {
      setLoading(true);
      try {
        const filesClone = [...files];
        for (let index = 0; index < filesClone.length; index++) {
          const file = filesClone[index];
          if (file.delete) {
            if (file.image && file.image.ref) {
              await deleteObject(ref(storage, file.image.ref));
            }
            filesClone.splice(index, 1);
          } else if (file.file) {
            const fileUploaded = await uploadBytes(ref(storage, `parks/${parkHook.park.id}/animals/${animal.id}/${file.file.name}`), file.file);
            const url = await getDownloadURL(fileUploaded.ref);
            filesClone[index].image = { src: url, alt: file.alt, ref: fileUploaded.ref.toString() } as Image;
          } else if (file.image) {
            filesClone[index].image = { ...file.image, alt: file.alt !== '' ? file.alt : file.image.alt } as Image;
          }
        }
        const images = filesClone.map((file: IFile) => file.image as Image);

        let thumbnailSrc = animal.thumbnail;
        if (thumbnail.file) {
          const regName = thumbnail.file.name.match(/(^[\w,\s-]+)(\.[A-Za-z]{3,4})$/);
          const name = regName?.length === 3 ? 'thumbnail' + regName[2] : thumbnail.file.name;
          const fileUploaded = await uploadBytes(ref(storage, `parks/${parkHook.park.id}/animals/${animal.id}/${name}`), thumbnail.file);
          const url = await getDownloadURL(fileUploaded.ref);
          thumbnailSrc = url;
        }
    
        if (animalId) {
          update(animalId, {
            ...animal,
            images: images,
            thumbnail: thumbnailSrc,
          }).then(() => {
            setLoading(false);
            navigate(`/animals/${animal.id}`);
          }).catch((error: any) => {
            console.log(error);
            setLoading(false);
          });
        } else {
          add({
            ...animal,
            images: images,
            thumbnail: thumbnailSrc,
            park: {
              id: parkHook.park.id,
              name: parkHook.park.name,
              country: parkHook.park.country,
            }
          }).then(() => {
            setLoading(false);
            navigate(`/animals/${animal.id}`);
          }).catch((error: any) => {
            console.log(error);
            setLoading(false);
          });
        }
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
      
      
    } else {
      setErrors(Animal.errors(animal));
    }
  }

  const handleChangeImages = async (filesAdded: IFile[]) => {
    setFiles(filesAdded);
  }

  const handleChangeThumbnail = async (filesAdded: IFile[]) => {
    setThumbnail(filesAdded[0]);
  }

  useEffect(() => {
    all(where('type', '==', 'animal'), where('main', '==', false)).then((categories: ICategory[]) => {
      setCategories(categories);
    });
    if (animalId) {
      get(animalId).then((a: IAnimal) => {
        setAnimal(a);
        if (a.images) {
          setFiles(a.images.map((image: Image) => ({ alt: '', image: image } as IFile)));
        }
        setThumbnail({ image: { alt: 'Thumbnail', src: a.thumbnail } as Image } as IFile);
      });
    } else {
      setAnimal(new Animal({id: doc(collection(db, 'animals')).id} as any));
    }
  }, [animalId]);

  return (
    <Box component="main" sx={{ flex: 1, pt: 2, pb: 6, px: 4, bgcolor: '#eaeff1' }}>
      <IconButton aria-label="back" sx={{ mb: 2 }} onClick={() => navigate(-1)}>
        <ArrowBackIcon />
      </IconButton>
      <Box
        noValidate
        component="form"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: "100%",
        }}
      >
        <Grid container spacing={2}>
          <Grid item lg={3}>
            
              <Card>
                <CardContent>
                <FormControl  error={!!errors.find((e) => e.name === 'thumbnail')} fullWidth>
                  <FileUploaderMultiple
                    files={thumbnail ? [thumbnail] : []}
                    label="Thumbnail"
                    onChange={handleChangeThumbnail}
                  />
                  <FormHelperText>{errors.find((e) => e.name === 'thumbnail')?.message}</FormHelperText>
                </FormControl>
                <FormControl sx={{ mt: 2 }} fullWidth>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Nom"
                    name="name"
                    required
                    value={animal.name}
                    onChange={handleChangeFieldText}
                    error={!!errors.find((e) => e.name === 'name')}
                    helperText={errors.find((e) => e.name === 'name')?.message}
                  />
                </FormControl>
                
                <FormControl error={!!errors.find((e) => e.name === 'category')} sx={{ mt: 2 }} fullWidth>
                  <InputLabel id="select-category">Catégorie</InputLabel>
                  <Select
                    labelId="select-category"
                    value={animal.category.id}
                    label="Catégorie"
                    required
                    onChange={(event) => {
                      handleChangeCategory(categories.find((c: ICategory) => c.id === event.target.value) as ICategory);
                    }}
                  >
                    {categories.map((c: any) => (<MenuItem key={c.id} value={c.id}>{c.slug}</MenuItem>))}
                  </Select>
                  <FormHelperText>{errors.find((e) => e.name === 'category')?.message}</FormHelperText>
                </FormControl>

                <FormControl sx={{ mt: 2 }} fullWidth>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Date de naissance"
                    name="birthDate"
                    value={animal.birthDate}
                    onChange={handleChangeFieldText}
                    error={!!errors.find((e) => e.name === 'birthDate')}
                    helperText={errors.find((e) => e.name === 'birthDate')?.message}
                  />
                </FormControl>

                <FormControl error={!!errors.find((e) => e.name === 'country')} sx={{ mt: 2 }} fullWidth>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Lieu de naissance"
                    name="birthPlace"
                    value={animal.birthPlace}
                    onChange={handleChangeFieldText}
                    error={!!errors.find((e) => e.name === 'birthPlace')}
                    helperText={errors.find((e) => e.name === 'birthPlace')?.message}
                  />
                </FormControl>
                </CardContent>
                <CardActions sx={{ justifyContent: "center"}}>
                  <LoadingButton
                    color="success"
                    onClick={handleSubmit}
                    loading={loading}
                    variant="contained"
                  >
                    {animalId ? 'Editer' : 'Ajouter'}
                  </LoadingButton>
                </CardActions>
              </Card>
            
          </Grid>
          <Grid item lg={9}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
              <Tabs value={tab} onChange={handleChangeTab}>
                <Tab label="Français" />
                <Tab label="Anglais" />
                <Tab label="Images" />
              </Tabs>
            </Box>
            <Box>
              <Box
                role="tabpanel"
                sx={{ display: tab !== 0 ? 'none' : 'block'}}
                id={`simple-tabpanel-${0}`}
                aria-labelledby={`simple-tab-${0}`}
              >
                <Card>
                  <CardContent>
                    <FormControl fullWidth>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Espèce"
                        placeholder="Loup gris"
                        required
                        value={animal.type.fr ?? ''}
                        onChange={(event) => {
                          setAnimal({
                            ...animal,
                            type: {
                              fr: event.target.value,
                              en: animal.type.en,
                            }
                          })
                        }}
                      />
                    </FormControl>
                    <FormControl fullWidth sx={{ mt: 2 }}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Courte description"
                        multiline
                        required
                        minRows={4}
                        maxRows={6}
                        value={animal.shortDescription.fr ?? ''}
                        onChange={(event) => {
                          setAnimal({
                            ...animal,
                            shortDescription: {
                              fr: event.target.value,
                              en: animal.shortDescription.en,
                            }
                          })
                        }}
                      />
                    </FormControl>
                    <FormControl fullWidth sx={{ mt: 2 }}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Longue description"
                        multiline
                        required
                        minRows={4}
                        maxRows={6}
                        value={animal.fullDescription.fr ?? ''}
                        onChange={(event) => {
                          setAnimal({
                            ...animal,
                            fullDescription: {
                              fr: event.target.value,
                              en: animal.fullDescription.en,
                            }
                          })
                        }}
                      />
                    </FormControl>
                  </CardContent>
                </Card>
              </Box>
              <Box
                role="tabpanel"
                sx={{ display: tab !== 1 ? 'none' : 'block'}}
                id={`simple-tabpanel-${1}`}
                aria-labelledby={`simple-tab-${1}`}
              >
                <Card>
                  <CardContent>
                    <FormControl fullWidth>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Espèce"
                        placeholder="Grey wolf"
                        required
                        value={animal.type.en ?? ''}
                        onChange={(event) => {
                          setAnimal({
                            ...animal,
                            type: {
                              en: event.target.value,
                              fr: animal.type.fr,
                            }
                          })
                        }}
                      />
                    </FormControl>
                    <FormControl fullWidth sx={{ mt: 2 }}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Courte description"
                        multiline
                        required
                        minRows={4}
                        maxRows={6}
                        value={animal.shortDescription.en ?? ''}
                        onChange={(event) => {
                          setAnimal({
                            ...animal,
                            shortDescription: {
                              en: event.target.value,
                              fr: animal.shortDescription.fr,
                            }
                          })
                        }}
                      />
                    </FormControl>
                    <FormControl fullWidth sx={{ mt: 2 }}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Longue description"
                        multiline
                        required
                        minRows={4}
                        maxRows={6}
                        value={animal.fullDescription.en ?? ''}
                        onChange={(event) => {
                          setAnimal({
                            ...animal,
                            fullDescription: {
                              en: event.target.value,
                              fr: animal.fullDescription.fr,
                            }
                          })
                        }}
                      />
                    </FormControl>
                  </CardContent>
                </Card>
              </Box>
              <Box
                role="tabpanel"
                sx={{ display: tab !== 2 ? 'none' : 'block'}}
                id={`simple-tabpanel-${2}`}
                aria-labelledby={`simple-tab-${2}`}
              >
                <FormControl  error={!!errors.find((e) => e.name === 'images')} fullWidth>
                  <FileUploaderMultiple
                    files={files}
                    label="Images"
                    multiple
                    onChange={handleChangeImages}
                  />
                  <FormHelperText>{errors.find((e) => e.name === 'images')?.message}</FormHelperText>
                </FormControl>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}