import DeleteIcon from "@mui/icons-material/Delete";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemText,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../auth/provideAuthHook";
import { usePark } from "../../core/hooks/park/provideParkHook";
import { IPark } from "../../core/models/park";
import { get, remove } from "../../core/services/park-service";
import { Dashboard } from "./Dashboard";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

export function ParkShow() {
  const authHook = useAuth();
  const parkHook = usePark();
  const [tab, setTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [nameConfirmDelete, setNameConfirmDelete] = useState("");
  const [errorConfirm, setErrorConfirm] = useState<string | undefined>();

  const [park, setPark] = useState<any>();

  const navigate = useNavigate();

  const handleChangeTab = (event: any, newValue: number) => {
    setTab(newValue);
  };

  const handleDelete = (event: any) => {
    if (authHook.superAdmin) {
      if (nameConfirmDelete === park?.name) {
        setErrorConfirm(undefined);
        setLoading(true);
        remove(park.id).then(() => {
          setLoading(false);
          parkHook.refresh().then(() => {
            parkHook.selectPark(parkHook.userParks[0]);
          });
        });
      } else {
        setErrorConfirm("Le nom n'est pas identique.");
      }
    }
  };

  const handleCloseDelete = () => {
    setErrorConfirm(undefined);
    setNameConfirmDelete("");
    setOpenConfirmDelete(false);
  };

  useEffect(() => {
    if (parkHook.park?.id) {
      get(parkHook.park.id).then((p: IPark) => {
        setPark(p);
      });
    }
  }, [parkHook.park?.id]);

  if (!park) {
    return <CircularProgress />;
  }

  return (
    <>
      {authHook.superAdmin && (
        <Dialog open={openConfirmDelete} onClose={handleCloseDelete}>
          <DialogTitle>Êtes-vous VRAIMENT certains de vouloir supprimer "{park.name}" ?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Cette action irrémédiable va <b>SUPPRIMER</b> le refuge, les images, les contreparties, les stories et les
              donations.
            </DialogContentText>
            <TextField
              autoFocus
              value={nameConfirmDelete}
              margin="dense"
              label={`Confirmer le nom du park: ${park.name}`}
              fullWidth
              variant="standard"
              error={!!errorConfirm}
              helperText={errorConfirm}
              onChange={(event) => {
                setErrorConfirm("");
                setNameConfirmDelete(event.target.value);
              }}
            />
          </DialogContent>
          <DialogActions>
            <LoadingButton loading={loading} onClick={handleCloseDelete}>
              Annuler
            </LoadingButton>
            <LoadingButton
              color="error"
              disabled={loading || nameConfirmDelete !== park.name}
              onClick={handleDelete}
              loading={loading}
              loadingPosition="start"
              startIcon={<DeleteIcon />}
              variant="contained"
            >
              Confirmer
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}
      <Box component="main" sx={{ flex: 1, pt: 2, pb: 6, px: 4, bgcolor: "#eaeff1" }}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Card>
              <CardMedia component="img" height="250" image={park.thumbnail} alt={park.name} />
              <CardContent>
                <Typography variant="h5" component="div" align="center">
                  {park.name}
                </Typography>
                <br />
                {park.donations && (
                  <Typography variant="caption" component="div" align="center">
                    {park.donations.donations ?? 0} € / {park.donations.goal ?? 0} €
                  </Typography>
                )}
                {park.donations && (
                  <BorderLinearProgress
                    variant="determinate"
                    value={
                      park.donations.donations >= park.donations.goal
                        ? 100
                        : ((park.donations?.donations ?? 0) / (park.donations?.goal ?? 0)) * 100
                    }
                  />
                )}
                <List>
                  <ListItem alignItems="flex-start">
                    <ListItemText primary="Nombre d'animaux" secondary={park.animalsCounter} />
                  </ListItem>
                  <ListItem alignItems="flex-start">
                    <ListItemText primary="Adresse" secondary={`${park.city}, ${park.country}`} />
                  </ListItem>
                  <ListItem alignItems="flex-start">
                    <ListItemText primary="Site internet" secondary={park.website} />
                  </ListItem>
                  <ListItem alignItems="flex-start">
                    <ListItemText primary="E-mail" secondary={park.email} />
                  </ListItem>
                  <ListItem alignItems="flex-start">
                    <ListItemText
                      primary="Point géographique"
                      secondary={`Latitude: ${park.localization?.geopoint?.latitude ?? ""}, Longitude: ${
                        park.localization?.geopoint?.longitude ?? ""
                      }`}
                    />
                  </ListItem>
                </List>
              </CardContent>
              <CardActions sx={{ justifyContent: "center" }}>
                {authHook.superAdmin && (
                  <Button
                    variant="text"
                    color="error"
                    onClick={() => {
                      setOpenConfirmDelete(true);
                    }}
                  >
                    Supprimer
                  </Button>
                )}
                <Button
                  variant="contained"
                  onClick={() => {
                    navigate(`/park/edit`);
                  }}
                >
                  Editer
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={9}>
            <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}>
              <Tabs value={tab} onChange={handleChangeTab}>
                <Tab label="Dashboard" />
                <Tab label="Animaux" />
                <Tab label="Équipe" />
              </Tabs>
            </Box>
            <Box>
              <div role="tabpanel" hidden={tab !== 0} id={`simple-tabpanel-${0}`} aria-labelledby={`simple-tab-${0}`}>
                <Dashboard />
              </div>
              <div role="tabpanel" hidden={tab !== 1} id={`simple-tabpanel-${1}`} aria-labelledby={`simple-tab-${1}`}>
                <Card>
                  <CardContent></CardContent>
                </Card>
              </div>
              <div role="tabpanel" hidden={tab !== 2} id={`simple-tabpanel-${2}`} aria-labelledby={`simple-tab-${2}`}>
                <Card>
                  <CardContent></CardContent>
                </Card>
              </div>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
