import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Switch,
} from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import InputLanguages from "../../../core/components/InputLanguages";
import { ILanguages } from "../../../core/models/languages";
import { Gift, Recurrence } from "../../../core/models/reward";

interface GiftEditProps {
  errors: { name: string; message: string }[];
  open: boolean;
  gift?: Gift;
  onClose: (gift?: Gift) => void;
}

export function GiftEdit(props: GiftEditProps) {
  const [submitted, setSubmitted] = useState(false);
  const [isConsumable, setIsConsumable] = useState(false);
  const [gift, setGift] = useState<Gift>(new Gift());

  const handleChangeName = (value: ILanguages) => {
    setGift({
      ...gift,
      name: value,
    });
  };

  const handleChangeDescription = (value: ILanguages) => {
    setGift({
      ...gift,
      description: value,
    });
  };

  const handleSubmit = () => {
    setSubmitted(true);
    props.onClose({
      ...gift,
      minDayConsumable: isConsumable ? gift.minDayConsumable : undefined,
      maxDayConsumable: isConsumable ? gift.maxDayConsumable : undefined,
      consumable: isConsumable ? gift.consumable : undefined,
      recurrenceConsumable: isConsumable && gift.isRecurrenceConsumable ? gift.recurrenceConsumable : undefined,
      isRecurrenceConsumable: isConsumable ? gift.isRecurrenceConsumable : undefined,
    });
  };

  useEffect(() => {
    if (props.gift) {
      setGift(props.gift);
      setIsConsumable(props.gift.consumable && props.gift.consumable > 0 ? true : false);
    }
  }, [props.gift]);

  return (
    <Fragment>
      <Dialog
        fullWidth
        maxWidth="md"
        open={props.open}
        onClose={() => props.onClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{props.gift ? `Modifier ${gift.name.fr}` : "Nouvelle contrepartie"}</DialogTitle>
        <DialogContent>
          <InputLanguages
            label="Nom"
            key="name"
            required
            value={gift.name}
            onChange={handleChangeName}
            error={props.errors.find((e) => e.name.includes("name"))}
          />

          <InputLanguages
            label="Description"
            key="description"
            required
            value={gift.description}
            onChange={handleChangeDescription}
            error={props.errors.find((e) => e.name.includes("description"))}
          />
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <FormControl sx={{ mt: 2 }} fullWidth>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        onChange={() => {
                          setGift({
                            ...gift,
                            onlyAnimal: !gift.onlyAnimal,
                            onlyPark: gift.onlyPark && !gift.onlyAnimal ? false : gift.onlyPark,
                          });
                        }}
                        checked={gift.onlyAnimal}
                      />
                    }
                    label="Concerne seulement l'animal"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl sx={{ mt: 2 }} fullWidth>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        onChange={() => {
                          setGift({
                            ...gift,
                            onlyAnimal: gift.onlyAnimal && !gift.onlyPark ? false : gift.onlyAnimal,
                            onlyPark: !gift.onlyPark,
                          });
                        }}
                        checked={gift.onlyPark}
                      />
                    }
                    label="Concerne seulement le parc"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl sx={{ mt: 2 }} fullWidth>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        onChange={() => {
                          setGift({
                            ...gift,
                            qrcode: !gift.qrcode,
                          });
                        }}
                        checked={gift.qrcode}
                      />
                    }
                    label="Créer un QR Code"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
          </Grid>
          <FormControl sx={{ mt: 2 }} fullWidth>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    onChange={() => {
                      setIsConsumable(!isConsumable);
                    }}
                    checked={isConsumable}
                  />
                }
                label="Utilisable"
              />
            </FormGroup>
          </FormControl>

          {isConsumable && (
            <Box>
              <FormControl sx={{ mt: 2 }} fullWidth>
                <InputLabel htmlFor="consumable-input">Combien de fois ?</InputLabel>
                <OutlinedInput
                  value={gift.consumable}
                  onChange={(event: any) => {
                    setGift({
                      ...gift,
                      consumable: +event.target.value,
                    });
                  }}
                  type="number"
                  id="consumable-input"
                  label="Combien de fois ?"
                />
              </FormControl>

              <FormControl sx={{ mt: 2 }} fullWidth>
                <InputLabel htmlFor="minDayConsumable-input">Minimum de jour avant de pouvoir l'utiliser</InputLabel>
                <OutlinedInput
                  value={gift.minDayConsumable}
                  onChange={(event: any) => {
                    setGift({
                      ...gift,
                      minDayConsumable: +event.target.minDayConsumable,
                    });
                  }}
                  type="number"
                  id="minDayConsumable-input"
                  label="Minimum de jour avant de pouvoir l'utiliser"
                />
                <FormHelperText>Optionnel</FormHelperText>
              </FormControl>

              <FormControl sx={{ mt: 2 }} fullWidth>
                <InputLabel htmlFor="maxDayConsumable-input">Nombre de jours avant l'expiration</InputLabel>
                <OutlinedInput
                  value={gift.maxDayConsumable}
                  onChange={(event: any) => {
                    setGift({
                      ...gift,
                      maxDayConsumable: +event.target.maxDayConsumable,
                    });
                  }}
                  type="number"
                  id="maxDayConsumable-input"
                  label="Nombre de jours avant l'expiration"
                />
                <FormHelperText>Optionnel</FormHelperText>
              </FormControl>

              <FormControl sx={{ mt: 2 }} fullWidth>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        onChange={() => {
                          setGift({
                            ...gift,
                            isRecurrenceConsumable: !gift.isRecurrenceConsumable,
                          });
                        }}
                        checked={gift.isRecurrenceConsumable}
                      />
                    }
                    label="L'utilisation peut se renouveler"
                  />
                </FormGroup>
              </FormControl>

              {gift.isRecurrenceConsumable && (
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel id="recurrence-select-label">Récurrence de l'utilisation</InputLabel>
                  <Select
                    labelId="recurrence-select-label"
                    id="recurrence-select"
                    value={gift.recurrenceConsumable}
                    label="Récurrence de l'utilisation"
                    onChange={(event: SelectChangeEvent) => {
                      setGift({
                        ...gift,
                        recurrenceConsumable: event.target.value as Recurrence,
                      });
                    }}
                  >
                    <MenuItem value="week">Toutes les semaines</MenuItem>
                    <MenuItem value="month">Tous les mois</MenuItem>
                    <MenuItem value="year">Tous les ans</MenuItem>
                  </Select>
                </FormControl>
              )}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => props.onClose()} variant="text">
            Annuler
          </Button>
          <Button onClick={handleSubmit} variant="contained">
            {gift.id ? "Modifier" : "Créer"}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
