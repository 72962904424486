import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { LoadingButton } from "@mui/lab";
import { Box, Card, CardActions, CardContent, Grid, IconButton } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { collection, doc, getDocs, query, where, writeBatch } from "firebase/firestore";
import { getBytes, getDownloadURL, listAll, ListResult, ref, StorageReference, uploadBytes } from "firebase/storage";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { db, storage } from "../../../config/firebase";
import { ADMIN_CATEGORY_SHOW } from "../../../config/routes";
import { IFile } from "../../../core/components/FileUploader";
import { Category, ICategory, Type } from "../../../core/models/category";
import { Image } from "../../../core/models/image";
import { add, get, update } from "../../../core/services/category-service";
import { FormComponent } from "./FormComponent";

export function CategoryEdit() {
  const { categoryId } = useParams();

  const [category, setCategory] = useState<ICategory>(new Category());
  const [thumbnail, setThumbnail] = useState<IFile>();
  const [imagesCategories, setImagesCategories] = useState<IFile[]>([]);
  const [icon, setIcon] = useState<IFile>();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<{ name: string; message: string }[]>([]);

  const navigate = useNavigate();

  const handleChangeFieldText = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCategory({
      ...category,
      [event.target.name]: event.target.value,
    });
    event.stopPropagation();
  };

  const handleChangeParents = (categories: ICategory[]) => {
    setCategory({
      ...category,
      parents: categories.map((c) => c.id),
    });
  };

  const handleChangeChildren = (categories: ICategory[]) => {
    setCategory({
      ...category,
      children: categories.map((c) => c.id),
    });
  };

  const handleChangeMain = (main: boolean) => {
    setCategory({
      ...category,
      main: main,
    });
  };

  const handleSubmit = async () => {
    if (Category.errors(category).length === 0 && thumbnail) {
      setLoading(true);
      try {
        let thumbnailSrc = category.thumbnail;
        if (thumbnail.file) {
          const regName = thumbnail.file.name.match(/(^[\w,\s-]+)(\.[A-Za-z]{3,4})$/);
          const name = regName?.length === 3 ? "thumbnail" + regName[2] : thumbnail.file.name;
          const fileUploaded = await uploadBytes(ref(storage, `categories/${category.id}/${name}`), thumbnail.file);
          const url = await getDownloadURL(fileUploaded.ref);
          thumbnailSrc = url;
        }

        let iconSrc = category.icon;

        if (icon?.image?.src && icon.image.src !== category.icon) {
          category.icon = icon.image.alt.replace("graoo-", "").replace(".svg", "");
          const refImg: StorageReference = icon.other;
          const bytes = await getBytes(refImg);
          const fileUploaded = await uploadBytes(ref(storage, `categories/${category.id}/icon.svg`), bytes, {
            contentType: "image/svg+xml",
          });
          const url = await getDownloadURL(fileUploaded.ref);
          iconSrc = url;
        }

        if (categoryId) {
          update(categoryId, {
            ...category,
            thumbnail: thumbnailSrc,
            icon: iconSrc,
          })
            .then(async () => {
              if (icon?.image?.src && category.type) {
                const items = await getDocs(
                  query(collection(db, `${category.type}s`), where("category.id", "==", categoryId))
                );
                const batch = writeBatch(db);
                items.docs.forEach((doc) => {
                  batch.update(doc.ref, {
                    "category.icon": iconSrc,
                  });
                });
                await batch.commit();
              }
              setLoading(false);
              navigate(ADMIN_CATEGORY_SHOW.replace(":categoryId", category.id));
            })
            .catch((error: any) => {
              console.log(error);
              setLoading(false);
            });
        } else {
          add({
            ...category,
            thumbnail: thumbnailSrc,
          })
            .then(() => {
              setLoading(false);
              navigate(ADMIN_CATEGORY_SHOW.replace(":categoryId", category.id));
            })
            .catch((error: any) => {
              console.log(error);
              setLoading(false);
            });
        }
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
    } else {
      setErrors(Category.errors(category));
    }
  };

  const handleChangeThumbnail = async (filesAdded: IFile[]) => {
    setThumbnail(filesAdded[0]);
  };

  const handleChangeIcon = async (file: IFile) => {
    setIcon(file);
  };

  const handleChangeType = (type: Type) => {
    setCategory({
      ...category,
      type,
    });
  };

  useEffect(() => {
    if (categoryId) {
      get(categoryId).then((c) => {
        setCategory(c);
        setThumbnail({ image: { alt: "Thumbnail", src: c.thumbnail } as Image } as IFile);
        // setIcon({ image: { alt: "icon", src: c.icon } as Image } as IFile);
      });
    } else {
      setCategory(new Category(doc(collection(db, "categories")).id));
    }
    listAll(ref(storage, `categories/icons`)).then(async (files: ListResult) => {
      const promises: any[] = [];
      files.items.forEach(async (file: StorageReference) => {
        promises.push({
          promise: getDownloadURL(ref(storage, file.fullPath)),
          file: file,
        });
      });
      Promise.all(promises.map((p: any) => p.promise)).then((res) => {
        setImagesCategories(
          res.map(
            (p: any, index: number) =>
              ({
                image: { alt: promises[index].file.name.replace("graoo-", "").replace(".svg", ""), src: p } as Image,
                other: promises[index].file,
              } as IFile)
          )
        );
        // setImagesCategories(images);
      });
    });
  }, [categoryId]);

  if (imagesCategories.length === 0) {
    return (
      <Box sx={{ display: "flex" }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box component="main" sx={{ flex: 1, pt: 2, pb: 6, px: 4, bgcolor: "#eaeff1" }}>
      <IconButton aria-label="back" sx={{ mb: 2 }} onClick={() => navigate(-1)}>
        <ArrowBackIcon />
      </IconButton>
      <Box
        noValidate
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Grid container spacing={2}>
          <Grid item lg={4} />
          <Grid item lg={4}>
            <Box
              noValidate
              component="form"
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Card>
                <CardContent>
                  <FormComponent
                    errors={errors}
                    file={thumbnail}
                    category={category}
                    imagesCategories={imagesCategories}
                    icon={icon}
                    handleChangeFieldText={handleChangeFieldText}
                    handleChangeThumbnail={handleChangeThumbnail}
                    handleChangeParents={handleChangeParents}
                    handleChangeChildren={handleChangeChildren}
                    handleChangeMain={handleChangeMain}
                    handleChangeType={handleChangeType}
                    handleChangeIcon={handleChangeIcon}
                  />
                </CardContent>
                <CardActions sx={{ justifyContent: "center" }}>
                  <LoadingButton color="success" onClick={handleSubmit} loading={loading} variant="contained">
                    {categoryId ? "Editer" : "Ajouter"}
                  </LoadingButton>
                </CardActions>
              </Card>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
