import { Box, Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IAnimal } from '../../../core/models/animal';
import DonationCompaign from './DonationCampaign';
import DonationCompaignPast from './DonationCampaignPast';
import DonationList from './DonationList';

interface DonationPageProps {
  animal: IAnimal;
  refresh: () => void;
}

export function DonationPage(props: DonationPageProps) {

  const [tab, setTab] = useState(0);

  const navigate = useNavigate();

  const handleChangeTab = (event: any, newValue: number) => {
    setTab(newValue);
  };

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
        <Tabs value={tab} onChange={handleChangeTab}>
          <Tab label="Gestion" />
          <Tab label="Campagnes passées" />
          <Tab label="Historique" />
          <Tab label="Donateurs" />
        </Tabs>
      </Box>
      <Box>
        <div
          role="tabpanel"
          hidden={tab !== 0}
          id={`simple-tabpanel-${0}`}
          aria-labelledby={`simple-tab-${0}`}
        >
          <DonationCompaign animal={props.animal} refresh={props.refresh} />
        </div>
        <div
          role="tabpanel"
          hidden={tab !== 1}
          id={`simple-tabpanel-${1}`}
          aria-labelledby={`simple-tab-${1}`}
        >
          <DonationCompaignPast animal={props.animal} />
        </div>
        
        <div
          role="tabpanel"
          hidden={tab !== 2}
          id={`simple-tabpanel-${2}`}
          aria-labelledby={`simple-tab-${2}`}
        >
          <DonationList />
        </div>
        <div
          role="tabpanel"
          hidden={tab !== 3}
          id={`simple-tabpanel-${3}`}
          aria-labelledby={`simple-tab-${3}`}
        >
          
        </div>
      </Box>
    </>
  );
}