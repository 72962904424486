// PUBLIC
export const HOME = "/";

// SUPER ADMIN
export const ADMIN_DASHBOARD = "/admin";
export const ADMIN_PARK_LIST = "/admin/parks";
export const ADMIN_PARK_NEW = "/admin/parks/new";
export const ADMIN_USER_LIST = "/admin/users";
export const ADMIN_USER_SHOW = "/admin/users/:userId";
export const ADMIN_CATEGORY_LIST = "/admin/categories";
export const ADMIN_CATEGORY_SHOW = "/admin/categories/:categoryId";
export const ADMIN_CATEGORY_NEW = "/admin/categories/new";
export const ADMIN_CATEGORY_EDIT = "/admin/categories/:categoryId/edit";

// PRIVATE PARK
export const USER_LIST = "/users";
export const USER_SHOW = "/users/:userId";

export const PARK_SHOW = "/park";
export const PARK_EDIT = "/park/edit";

export const ANIMAL_LIST = "/animals";
export const ANIMAL_SHOW = "/animals/:animalId";
export const ANIMAL_NEW = "/animals/new";
export const ANIMAL_EDIT = "/animals/:animalId/edit";

export const PARK_USER_LIST = "/park/users";
export const PARK_USER_SHOW = "/park/users/:userId";
export const PARK_USER_EDIT = "/park/users/:userId/edit";
