import { collection, deleteDoc, doc, getDoc, getDocs, query, setDoc } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { User } from '../models/user';

const REF = 'users';

export const all = async (...where: any): Promise<User[]> => {
  const ref = collection(db, REF);
  let users;
  if (where) {
    users = await getDocs(query(ref, ...where));
  } else {
    users = await getDocs(ref);
  }

  if (!users.empty) {
    return users.docs.map((a) => ({
        ...a.data(),
        id: a.id
      } as User
    ));
  }
  return [];
};

export const get = async (userId: string): Promise<User> => {
  const user = await getDoc(doc(db, REF, userId));
  if (user.exists()) {
    return {
        ...user.data(),
        id: user.id
      } as User
    ;
  }
  throw new Error('User not found');
};

export const update = async (userId: string, user: Partial<User>): Promise<void> => {
  const updatedAnimal = { ...user } as any;
  

  delete updatedAnimal.id;

  await setDoc(doc(db, REF, userId), updatedAnimal, { merge: true });
};

export const remove = async (userId: string): Promise<void> => {
  await deleteDoc(doc(db, REF, userId));
};
