import PersonIcon from '@mui/icons-material/Person';
import { Alert, Avatar, CircularProgress, Dialog, DialogContent, DialogTitle, List, ListItem, ListItemAvatar, ListItemText, useMediaQuery, useTheme } from "@mui/material";
import { GoogleAuthProvider } from 'firebase/auth';
import { useState } from "react";
import { useAuth } from '../../auth/provideAuthHook';

export interface LoginDialogProps {
  open: boolean;
  onClose: (logged: boolean) => void;
}

export function LoginDialog(props: LoginDialogProps) {
  const { onClose, open } = props;

  const [error, setError] = useState('');


  const auth = useAuth();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const onSubmit = async (provider: string) => {
    setError('');
    
    auth.signin(provider);
  };

  return (
    <Dialog open={open} fullScreen={fullScreen} fullWidth={true}>
      <DialogTitle>Connexion</DialogTitle>
      {error !== '' && <Alert severity="error">{error}</Alert>}
      <List sx={{ pt: 0 }}>
        <ListItem disabled={auth.loading} autoFocus button onClick={() => onSubmit(GoogleAuthProvider.PROVIDER_ID)}>
          <ListItemAvatar>
            {auth.loading ? <CircularProgress /> : <Avatar>
              <PersonIcon />
            </Avatar>}
          </ListItemAvatar>
          <ListItemText primary="Me connecter avec Google" />
        </ListItem>
      </List>
    </Dialog>
  );
}
