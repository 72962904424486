import { documentId, where } from 'firebase/firestore';
import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useAuth } from "../../../auth/provideAuthHook";
import { IPark } from "../../models/park";
import { all } from "../../services/park-service";

export const parkContext = createContext<{
  park: any;
  userParks: any;
  loading: boolean;
  refresh: () => Promise<void>;
  selectPark: (park: IPark | null) => void;
}>({
  park: null,
  userParks: [],
  loading: false,
  refresh: async () => { return; },
  selectPark: () => null,
});

export const usePark = () => {
  return useContext(parkContext);
}

export const useProvidePark = () => {
  const auth = useAuth();

  const [park, setPark] = useState<IPark | null>(null);
  const [userParks, setUserParks] = useState<{ roles: string[], park: IPark}[]>([]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const handleUserParks = useCallback(async () => {
    if (auth.claims) {
      if (auth.superAdmin) {
        setLoading(true);
        const parks = await all();
        if (parks.length > 0) {
          setUserParks(parks.map((p) => ({
            roles: ['admin'],
            park: p
          })));
          const localPark = localStorage.getItem('park');
          const findPark = localPark && localPark !== '' ? parks.find((p) => p.id === localPark) : null;
          if (!findPark) {
            localStorage.setItem('park', parks[0].id);
          }
          setPark(findPark ?? parks[0]);
        }
        setLoading(false);
      } else {
        const parksClaim = auth.claims.parks;
        if (parksClaim?.length > 0) {
          setLoading(true);
          const parks = await all(where(documentId(), 'in', parksClaim.map((p) => p.id)));
          if (parks.length > 0) {
            setUserParks(parks.map((p) => ({
              roles: parksClaim.find((c) => c.id === p.id)?.roles ?? [],
              park: p
            })));
            const localPark = localStorage.getItem('park');
            const findPark = localPark && localPark !== '' ? parks.find((p) => p.id === localPark) : null;
            setPark(findPark ?? parks[0]);
          }
          setLoading(false);
        }
      }
    }
  }, [auth.claims, auth.superAdmin]);

  const refresh = () => {
    return handleUserParks();
  };

  const selectPark = (park: IPark | null) => {
    setPark(park);
    localStorage.setItem('park', park?.id ?? '');
  }

  useEffect(() => {
    handleUserParks();
  }, [handleUserParks]);

  return {
    park,
    userParks,
    loading,
    refresh,
    selectPark
  };
}
