import { Category } from "./category";
import { Donation } from "./donation";
import { Image } from "./image";
import { Languages } from "./languages";

export enum Gender {
  "male",
  "female",
  "unknown",
}

export interface IAnimal {
  id: string;
  disable?: boolean;
  name: string;
  thumbnail: string;
  images: Image[];
  category: {
    icon: string;
    id: string;
    slug: string;
  };
  park?: {
    name: string;
    id: string;
    country: string;
  };
  type: Languages;
  shortDescription: Languages;
  fullDescription: Languages;
  birthDate?: string;
  birthPlace?: string;
  gender?: Gender;
  campaign?: Languages;
  donations?: Donation;
  custom: {
    title: Languages;
    content: Languages;
  }[];
}

export class Animal implements IAnimal {
  id: string;
  disable?: boolean;
  name: string;
  thumbnail: string;
  images: Image[];
  category: {
    icon: string;
    id: string;
    slug: string;
  };
  type: Languages;
  shortDescription: Languages;
  fullDescription: Languages;
  birthDate?: string;
  birthPlace?: string;
  gender?: Gender;
  campaign?: Languages;
  donations?: Donation;
  custom: {
    title: Languages;
    content: Languages;
  }[];

  constructor(animal?: IAnimal) {
    this.id = animal?.id ?? "";
    this.disable = animal?.disable ?? false;
    this.name = animal?.name ?? "";
    this.thumbnail = animal?.thumbnail ?? "";
    this.images = animal?.images ?? [];
    this.category = animal?.category ?? new Category();
    this.type = animal?.type ?? new Languages();
    this.shortDescription = animal?.shortDescription ?? new Languages();
    this.fullDescription = animal?.fullDescription ?? new Languages();
    this.birthDate = animal?.birthDate ?? "";
    this.birthPlace = animal?.birthPlace ?? "";
    this.gender = animal?.gender ?? Gender.unknown;
    this.campaign = animal?.campaign ?? new Languages();
    this.donations = animal?.donations ?? undefined;
    this.custom = animal?.custom ?? [];
  }

  static errors(animal: IAnimal) {
    const errors = [];
    if (animal.name === "") {
      errors.push({
        name: "name",
        message: "Le nom est obligatoire",
      });
    }
    if (animal.category.id === "") {
      errors.push({
        name: "category",
        message: "La catégorie est obligatoire",
      });
    }

    if (animal.shortDescription.fr === "" || animal.shortDescription.en === "") {
      errors.push({
        name: "shortDescription",
        message: "La courte description est obligatoire en français et en anglais",
      });
    }
    if (animal.fullDescription.fr === "" || animal.fullDescription.en === "") {
      errors.push({
        name: "fullDescription",
        message: "La longue description est obligatoire en français et en anglais",
      });
    }

    return errors;
  }
}
