import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, query, setDoc } from "firebase/firestore";
import { db } from "../../config/firebase";
import { ICategory } from "../models/category";

const REF = "categories";

export const all = async (...where: any): Promise<ICategory[]> => {
  const ref = collection(db, REF);
  let categories;
  if (where) {
    categories = await getDocs(query(ref, ...where));
  } else {
    categories = await getDocs(ref);
  }

  if (!categories.empty) {
    return categories.docs.map(
      (c) =>
        ({
          ...c.data(),
          id: c.id,
        } as ICategory)
    );
  }
  return [];
};

export const get = async (categoryId: string): Promise<ICategory> => {
  const category = await getDoc(doc(db, REF, categoryId));
  if (category.exists()) {
    return {
      ...category.data(),
      id: category.id,
    } as ICategory;
  }
  throw new Error("Category not found");
};

export const add = async (category: ICategory): Promise<ICategory> => {
  const categoryCloned = { ...category, animalsCounter: 0, parksCounter: 0 } as any;
  delete categoryCloned.id;

  if (category.id && category.id !== "") {
    await setDoc(doc(db, REF, category.id), categoryCloned, { merge: true });
  } else {
    await addDoc(collection(db, REF), categoryCloned);
  }

  return category;
};

export const update = async (categoryId: string, category: Partial<ICategory>): Promise<void> => {
  const categoryCloned = { ...category } as any;

  delete categoryCloned.id;

  await setDoc(doc(db, REF, categoryId), categoryCloned, { merge: true });
};

export const remove = async (categoryId: string): Promise<void> => {
  await deleteDoc(doc(db, REF, categoryId));
};
