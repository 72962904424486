import { FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { where } from "firebase/firestore";
import { Fragment, useEffect, useState } from "react";
import FileUploaderMultiple, { IFile } from "../../core/components/FileUploader";
import InputLanguages from "../../core/components/InputLanguages";
import { ICategory } from "../../core/models/category";
import { ILanguages } from "../../core/models/languages";
import { IPark } from "../../core/models/park";
import { all } from "../../core/services/category-service";

interface FormComponentProps {
  errors: { name: string; message: string }[];
  files: IFile[];
  park: IPark;
  handleChangeFieldText: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeImage: (files: IFile[]) => void;
  handleChangeFieldLatitude: (value: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeFieldLongitude: (value: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeCategory: (category: ICategory) => void;
  handleChangeShortDescription: (value: ILanguages) => void;
  handleChangeFullDescription: (value: ILanguages) => void;
}

export function FormComponent(props: FormComponentProps) {
  const [categories, setCategories] = useState<ICategory[]>([]);

  useEffect(() => {
    all(where("type", "==", "park"), where("main", "==", false)).then((categories: ICategory[]) => {
      setCategories(categories);
    });
  }, []);

  return (
    <Fragment>
      <FormControl error={!!props.errors.find((e) => e.name === "images")} fullWidth>
        <FileUploaderMultiple files={props.files} onChange={props.handleChangeImage} />
        <FormHelperText>{props.errors.find((e) => e.name === "images")?.message}</FormHelperText>
      </FormControl>
      <FormControl sx={{ mt: 2 }} fullWidth>
        <TextField
          fullWidth
          variant="outlined"
          label="Nom"
          name="name"
          required
          value={props.park.name}
          onChange={props.handleChangeFieldText}
          error={!!props.errors.find((e) => e.name === "name")}
          helperText={props.errors.find((e) => e.name === "name")?.message}
        />
      </FormControl>

      <FormControl error={!!props.errors.find((e) => e.name === "category")} sx={{ mt: 2 }} fullWidth>
        <InputLabel id="select-category">Catégorie</InputLabel>
        <Select
          labelId="select-category"
          value={props.park.category.id}
          label="Catégorie"
          required
          onChange={(event) => {
            props.handleChangeCategory(categories.find((c: ICategory) => c.id === event.target.value) as ICategory);
          }}
        >
          {categories.map((c: any) => (
            <MenuItem key={c.id} value={c.id}>
              {c.slug}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{props.errors.find((e) => e.name === "category")?.message}</FormHelperText>
      </FormControl>

      <FormControl sx={{ mt: 2 }} fullWidth>
        <TextField
          fullWidth
          variant="outlined"
          label="Ville"
          name="city"
          required
          value={props.park.city}
          onChange={props.handleChangeFieldText}
          error={!!props.errors.find((e) => e.name === "city")}
          helperText={props.errors.find((e) => e.name === "city")?.message}
        />
      </FormControl>

      <FormControl error={!!props.errors.find((e) => e.name === "country")} sx={{ mt: 2 }} fullWidth>
        <TextField
          fullWidth
          variant="outlined"
          label="Pays"
          name="country"
          required
          value={props.park.country}
          onChange={props.handleChangeFieldText}
          error={!!props.errors.find((e) => e.name === "country")}
          helperText={props.errors.find((e) => e.name === "country")?.message}
        />
      </FormControl>

      <FormControl sx={{ mt: 2 }} fullWidth>
        <TextField
          fullWidth
          variant="outlined"
          label="Site internet"
          name="website"
          value={props.park.website ?? ""}
          onChange={props.handleChangeFieldText}
        />
      </FormControl>

      <FormControl sx={{ mt: 2 }} fullWidth>
        <TextField
          fullWidth
          variant="outlined"
          label="Email"
          name="email"
          value={props.park.email ?? ""}
          onChange={props.handleChangeFieldText}
        />
      </FormControl>

      <FormControl error={!!props.errors.find((e) => e.name === "localization.latitude")} sx={{ mt: 2 }} fullWidth>
        <TextField
          fullWidth
          variant="outlined"
          label="Latitude"
          name="localization.latitude"
          type="number"
          value={props.park.localization.geopoint.latitude}
          onChange={props.handleChangeFieldLatitude}
          error={!!props.errors.find((e) => e.name === "localization.latitude")}
          helperText={props.errors.find((e) => e.name === "localization.latitude")?.message}
        />
      </FormControl>

      <FormControl error={!!props.errors.find((e) => e.name === "localization.longitude")} sx={{ mt: 2 }} fullWidth>
        <TextField
          fullWidth
          variant="outlined"
          label="Longitude"
          type="number"
          name="localization.longitude"
          value={props.park.localization.geopoint.longitude}
          onChange={props.handleChangeFieldLongitude}
          error={!!props.errors.find((e) => e.name === "localization.longitude")}
          helperText={props.errors.find((e) => e.name === "localization.longitude")?.message}
        />
      </FormControl>

      <InputLanguages
        label="Courte description"
        required
        key="shortDescription"
        value={props.park.shortDescription}
        onChange={props.handleChangeShortDescription}
      />

      <InputLanguages
        label="Longue description"
        required
        key="fullDescription"
        value={props.park.fullDescription}
        onChange={props.handleChangeFullDescription}
      />
    </Fragment>
  );
}
