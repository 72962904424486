import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyANeR37476kP_zvhL48Znp2vC89hA3Hnng",
  authDomain: "graou-26802.firebaseapp.com",
  projectId: "graou-26802",
  storageBucket: "graou-26802.appspot.com",
  messagingSenderId: "780544620998",
  appId: "1:780544620998:web:04e4b8a8b75f77b6461ac7",
  measurementId: "G-1WGWPQJ49P"
};

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);
export const functions = getFunctions(app, 'europe-west3');
// connectFunctionsEmulator(functions, "localhost", 5001);
// connectFirestoreEmulator(db, "localhost", 8080);
// connectAuthEmulator(auth, "http://localhost:9099");
