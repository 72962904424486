import { Box, Button, List, ListItem, ListItemText } from "@mui/material";
import { useEffect, useState } from "react";
import MenuButton from "../../../core/components/MenuButton";
import { Gift } from "../../../core/models/reward";
import { addGift, editGift } from "../../../core/services/animal-service";
import { GiftEdit } from "./GiftEdit";

export default function GiftManagement(props: any) {
  const [gifts, setGifts] = useState<Gift[]>([]);
  const [giftSelectedIndex, setGiftSelectedIndex] = useState<number>(-1);
  const [openEditGift, setOpenEditGift] = useState(false);
  const [errors, setErrors] = useState<{ name: string; message: string }[]>([]);

  const handleClickOpenEditGift = (index: number) => {
    setGiftSelectedIndex(index);
    setOpenEditGift(true);
  };

  const handleCloseEditGift = (gift?: Gift) => {
    setErrors([]);

    if (gift) {
      if (Gift.errors(gift).length === 0) {
        if (gift.id && giftSelectedIndex >= 0) {
          editGift(props.animal.id, gift.id, {
            name: gift.name,
            description: gift.description,
            qrcode: gift.qrcode,
            consumable: gift.consumable,
            isRecurrenceConsumable: gift.isRecurrenceConsumable,
            minDayConsumable: gift.minDayConsumable,
            maxDayConsumable: gift.maxDayConsumable,
            recurrenceConsumable: gift.recurrenceConsumable,
            onlyAnimal: gift.onlyAnimal,
            onlyPark: gift.onlyPark,
          }).then(() => {
            setOpenEditGift(false);
            setGiftSelectedIndex(-1);
            gifts[giftSelectedIndex] = {
              ...gifts[giftSelectedIndex],
              ...gift,
            };
            setGifts(gifts);
          });
        } else {
          addGift(props.animal.id, {
            name: gift.name,
            description: gift.description,
            qrcode: gift.qrcode,
            consumable: gift.consumable,
            isRecurrenceConsumable: gift.isRecurrenceConsumable,
            minDayConsumable: gift.minDayConsumable,
            maxDayConsumable: gift.maxDayConsumable,
            recurrenceConsumable: gift.recurrenceConsumable,
            onlyAnimal: gift.onlyAnimal,
            onlyPark: gift.onlyPark,
          }).then((gift: Gift) => {
            setOpenEditGift(false);
            setGiftSelectedIndex(-1);
            gifts.push(gift);
            setGifts(gifts);
          });
        }
      } else {
        setErrors(Gift.errors(gift));
      }
    } else {
      setGiftSelectedIndex(-1);
      setOpenEditGift(false);
    }
  };

  useEffect(() => {
    setGifts(props.gifts);
  }, [props.gifts]);

  return (
    <Box>
      {openEditGift && (
        <GiftEdit
          errors={errors}
          gift={giftSelectedIndex >= 0 ? gifts[giftSelectedIndex] : undefined}
          open={openEditGift}
          onClose={handleCloseEditGift}
        />
      )}
      <Button onClick={() => handleClickOpenEditGift(-1)} variant="contained" color="success">
        Ajouter une contrepartie
      </Button>
      <List>
        {gifts.map((gift, index) => {
          return (
            <ListItem
              key={index}
              secondaryAction={
                <MenuButton
                  items={[
                    { name: "Modifier", onClick: () => handleClickOpenEditGift(index) },
                    { name: "Supprimer", onClick: () => {} },
                  ]}
                />
              }
            >
              <ListItemText primary={`${gift.name.fr}`} />
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
}
